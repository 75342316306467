<template>
  <div>
    <div class="d-flex justify-content-between align-content-center  px-5"> 
      <div class="d-flex align-items-center">
        <inline-svg :src="require('@/assets/Icons/globalmobility/scooter.svg')" />
        <h4 class="font-color-4 font-weight-bold mb-0 ml-2" >
          Scooter {{data.id}}
        </h4>
      </div>
      <div class="d-flex align-items-center">
        <inline-svg
          :src="require('@/assets/icons-2/Ellipse.svg')"
          width="15"
          height="15" />
        <span :class="['font-color-4 font-weight-bold ml-2']">
          OFF
        </span>
      </div>
      <div class="d-flex align-items-center">
        <inline-svg :class="`mx-2`" :src="require('@/assets/Icons/globalmobility/Ellipse.svg')" />
        <span :class="['font-color-4 font-weight-bold']">
          <b>Mode:</b> {{data.mode ? data.mode : "ECO"}}
        </span>
      </div>
      <div class="d-flex align-items-center">
        <inline-svg :class="`mx-2`" :src="require('@/assets/Icons/globalmobility/lock-24px.svg')" />
        <span :class="['font-color-4 font-weight-bold']">
          {{ data.is_locked ? "Locked" : "Unlocked"}}
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-content-center px-5 mt-4">
      <div class="d-flex align-items-center">
        <inline-svg
          :src="require('@/assets/Icons/globalmobility/battery.svg')"
          width="35"
          height="35"
          />
        <span :class="['font-color-4 font-weight-bold ml-2']">
          {{data.battery_level}}%
        </span>
      </div>
      <div class="d-flex align-items-center">
        <span class="font-color-4 font-weight-bold">GNSS</span>
        <inline-svg :class="`mx-2`" :src="require('@/assets/Icons/globalmobility/batter-high.svg')" />
        <span :class="['font-color-4 font-weight-bold']">
          {{data.gnss_status ? "ON": "OFF"}}
        </span>
      </div>
      <div class="d-flex align-items-center">
        <span class="font-color-4 font-weight-bold">GMS</span>
        <inline-svg :class="`mx-2`" :src="require('@/assets/Icons/globalmobility/batter-low.svg')" />
        <span :class="['font-weight-bold problem']">
          {{data.gsm_signal ? "ON": "OFF"}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "map-header-data",
  props:{
    data: {
    }
  }
}
</script>