<template>
  <card class="logs-filter">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
          <div>
            <label
              ><b>{{ $t("targets") }}</b>
            </label>
            <div class="targets scroll" dir="rtl">
              <div dir="ltr">
                <base-checkbox
                  v-for="(value, key) in filterOptions.targets"
                  :key="key"
                  v-model="filterOptions.targets[key]"
                >
                  {{ $t(key) }}
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>

      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 mb-4">
          <div>
            <label>
              <b>{{ $t("action") }}</b>
            </label>
            <div class="actions scroll" dir="rtl">
              <div dir="ltr">
                <base-checkbox
                  v-for="(value, key) in filterOptions.action"
                  :key="key"
                  v-model="filterOptions.action[key]"
                >
                  {{ $t(key) }}
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>


      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-1 mb-4">
          <div>
            <label>
              <b>{{ $t("theLevel") }}</b>
            </label>

            <base-checkbox
              v-for="(value, key) in filterOptions.level"
              :key="key"
              v-model="filterOptions.level[key]"
            >
              {{ $t(key) }}
            </base-checkbox>
          </div>
        </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-4">
        <div clas="row">
          <div
            class="d-flex justify-content-center"
            v-if="initialQueryString !== undefined"
          >
            <h3>
              Logs for {{ initialQueryString.target_type.slice(0, -3) }} :
            </h3>
            <div class="px-2"></div>
            <h3>
              {{ initialQueryString.target_name }}
            </h3>
          </div>
          <div v-else>
            <AutoCompleteFilterTargets
              :isReset="isReset"
              @target="handleTarget"
              ref="targetSelector"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-end mt-5">
            <base-button
              type="button"
              size="sm"
              :disabled="!isResetActive"
              @click="reset"
            >
              {{ $t("instructions.reset") }}
            </base-button>
            <base-button
              type="primary"
              size="sm"
              :disabled="!isChanged"
              @click="apply"
              >{{ $t("instructions.apply") }}</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import AutoCompleteFilterTargets from "./AutoCompleteFilterTargets";
export default {
  name: "LogsFilter",
  mounted() {
    this.initialQueryString = this.$route.query;
    if (this.initialQueryString["target_type"] !== undefined) {
      this.filterOptions["target"] = {
        target_id: parseInt(this.initialQueryString.target_id),
        target_key: this.initialQueryString.target_type,
        target_name: this.initialQueryString.target_name,
      };
      this.apply();
    } else {
      this.initialQueryString = undefined;
    }
    this.oldFilter = JSON.stringify(this.filterOptions);
  },
  computed: {
    isResetActive() {
      console.log("called");
      let isFilterHasTrueValue = false;
      if (
        this.objectHasTrueValue(this.filterOptions.level) ||
        this.objectHasTrueValue(this.filterOptions.action) ||
        this.objectHasTrueValue(this.filterOptions.targets) ||
        this.filterOptions["target"] !== undefined ||
        this.initialQueryString !== undefined
      ) {
        isFilterHasTrueValue = true;
      }
      return isFilterHasTrueValue;
    },
    isChanged() {
      return JSON.stringify(this.filterOptions) != this.oldFilter;
    },
  },
  methods: {
    reset() {
      this.isReset = true;
      if (this.$refs["targetSelector"]) this.$refs["targetSelector"].reset();
      this.initialQueryString = undefined;
      this.resetFilter(this.filterOptions.targets);
      this.resetFilter(this.filterOptions.level);
      this.resetFilter(this.filterOptions.action);
      delete this.filterOptions["target"];
      this.$emit("reset");
      history.pushState({}, null, this.$route.path);
    },
    apply() {
      this.oldFilter = JSON.stringify(this.filterOptions);
      this.$emit("input", this.filterOptions);
    },
    resetFilter(resetedObject) {
      // loop on object properities and convert it's value to false
      Object.keys(resetedObject).forEach((key) => {
        resetedObject[key] = false;
      });
    },
    objectHasTrueValue(object) {
      // check if there is any value in this object is true
      return Object.keys(object).some((key) => object[key]);
    },
    handleTarget(target) {
      // if there is a target comes from AutoComplete... component fill target object then call filter api
      if (target) {
        this.filterOptions["target"] = {
          target_key: target.target_key,
          target_id: target.target_id,
        };
        this.filterOptions.target.target_key = target.target_key;
        this.apply();
      } else {
        // check if old value for target object is null don't call api again
        if (!this.filterOptions["target"]) {
          return;
        } else {
          this.filterOptions["target"] = undefined;
          this.apply();
        }
      }
    },
  },
  components: {
    AutoCompleteFilterTargets,
  },
  data: function () {
    return {
      initialQueryString: undefined,
      oldFilter: "",
      isEqual: { type: Boolean, default: true },
      isReset: false,
      filterOptions: {
        target: undefined,
        level: {
          info: false,
          error: false,
          warn: false,
        },
        action: {
          create: false,
          edit: false,
          delete: false,
          login: false,
          logout: false,
        },
        targets: {
          user: false,
          company: false,
          amc: false,
          fmc: false,
          authority: false,
          paying_customer: false,
          operator: false,
          maintenance_order: false,
          alarm: false,
          building: false,
          device: false,
          manufacturer: false,
          order: false,
          sensor: false,
          structure: false,
          template: false,
          exception: false,
          maintenance: false,
          alarm_notifications_template: false,
        },
      },
    };
  },
};
</script>


<style lang="scss" scoped>
.logs-filter {
  p {
    margin: 1rem 0 0 0;
  }
  .actions,
  .targets {
    padding: 0 1rem;
    max-height: 123px;
    overflow: auto;
  }

  .scroll::-webkit-scrollbar {
    width: 6px;
  }
  .scroll::-webkit-scrollbar-track {
    background-color: #00000010 !important;
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: $primary;
  }

  .scroll:hover::-webkit-scrollbar-thumb {
    background-color: rgba($primary, $alpha: 0.8);
  }
}
</style>
