<template>
  <div>
    <LogsFilter @reset="reset" @input="handleSubmit" />
    <LogsTable
      @updatePagination="updatePagination"
      :logs="logs"
      :total="total"
    />
  </div>
</template>

<script>
import LogsFilter from "./LogsFilter";
import LogsTable from "./LogsTable";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Logs",
  components: { LogsFilter, LogsTable },
  data: function () {
    return {
      filterOptionsCopy: null,
    };
  },
  computed: mapGetters(["logs", "total"]),
  methods: {
    ...mapActions(["fetchLogs", "updatePaginationAction","resetPagination"]),
    reset() {
      let pagination={limit:10,offset:0};
      this.updatePaginationAction(pagination);
      this.filterOptionsCopy = null;
    },
    handleSubmit(filterOptions) {
      this.filterOptionsCopy = JSON.parse(JSON.stringify(filterOptions));
      this.fetchLogs({
        filterOptions: this.filterOptionsCopy
      });
    },
    updatePagination(pagination) {
      this.updatePaginationAction(pagination)
      this.fetchLogs({
        filterOptions: this.filterOptionsCopy
      });
    },
  },
  mounted() {
    if (this.filterOptionsCopy === null) {
      this.fetchLogs({
        filterOptions: null
      });
    }
  },
};
</script>
