<template>
  <div>
    <div class="start-popover-content-wrapper">
      <slot name="start-popover" />
    </div>
    <div class="end-popover-content-wrapper">
      <slot name="end-popover" />
    </div>
  </div>
</template>

<script>
export default {
  name: "base-map-path",
  props: {
    path: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    line: null,
    startMarker: null,
    endMarker: null
  }),
  mounted() {
    this.$parent.getMap(map => {
      this.line = new google.maps.Polyline({
        path: this.path,
        geodesic: true,
        strokeColor: "#f2aa24",
        strokeOpacity: 1.0,
        strokeWeight: 4,
        ...this.options
      });

      this.startMarker = new window.google.maps.Marker({
        position: this.path[0],
        map: map,
        icon: this.startIcon
      });

      if (this.$slots["start-popover"])
        this.setupPopover(
          map,
          this.startMarker,
          "start-popover-content-wrapper"
        );

      this.endMarker = new window.google.maps.Marker({
        position: this.path[this.path.length - 1],
        map: map,
        icon: this.endIcon
      });

      if (this.$slots["end-popover"])
        this.setupPopover(map, this.endMarker, "end-popover-content-wrapper");

      this.line.setMap(map);
    });
  },
  computed: {
    startIcon() {
      return require("src/assets/icons-2/Ellipse.svg");
    },
    endIcon() {
      return require("src/assets/icons-2/google-map-marker.svg");
    }
  },
  methods: {
    getPopoverContent(className) {
      return this.$el.getElementsByClassName(className)[0].innerHTML;
    },
    setupPopover(map, marker, className) {
      let popover = new window.google.maps.InfoWindow({
        content: this.getPopoverContent(className)
      });

      marker.addListener("mouseover", event => {
        popover.open(map, marker);
      });
      marker.addListener("mouseout", event => {
        popover.close(map, marker);
      });

      let observer = new MutationObserver(mutations => {
        popover.setContent(this.getPopoverContent(className));
      });

      observer.observe(this.$el.getElementsByClassName(className)[0], {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      });
    }
  },
  watch: {},
  beforeDestroy() {},
  render() {
    return null;
  }
};
</script>

<style scoped>
.start-popover-content-wrapper {
  display: none;
}
.end-popover-content-wrapper {
  display: none;
}
</style>
