<template>
  <card class="card">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2" >
        <div class="d-flex align-items-center">
          <h4 class="title ml-2 mb-0">Alarms</h4>
        </div>
        <div class="d-flex align-items-center justify-content-center ">
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            :simple="!state.state"
            class="py-1 btn-tab"
            v-for="(state, i) in states"
            :key="i"
            @click="changeState(state)"
            >{{ $t(`${state.title}`) }}</base-button >
        </div>
        <div>
          <base-select
            :inline="true"
            :light="true"
            v-model="currentPeriodIndex"
            :options="periodsOptions"
          />
        </div>
      </div>
    </template>
    <div class="d-flex align-items-center justify-content-center pb-2">
      <div>
        <div slot="right-footer" class="rounded">
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            :simple="!type.dataType"
            class="py-1 btn-tab"
            v-for="(type, i) in types"
            :key="i"
            @click="changeDateType(type)"
            >{{ $t(`${type.title}`) }}</base-button >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="currentType === 'All'">
          <movement-alarms :data="data.movement"></movement-alarms>
          <geofencing-alarms :data="data.geofencing"></geofencing-alarms>
          <communication-alarms :data="data.communication"></communication-alarms>
        </div>
        <movement-alarms
          v-if="currentType === 'Movement'"
          :data="data.movement"/>
        <communication-alarms
          v-if="currentType === 'Communication'"
          :data="data.communication"/>
        <geofencing-alarms
          v-if="currentType === 'Geofencing'"
          :data="data.geofencing"/>
      </div>
    </div>
  </card>
</template>

<script>
import MovementAlarms from "./MovementAlarms";
import CommunicationAlarms from "./CommunicationAlarms";
import GeofencingAlarms from "./GeofencingAlarms";
import { mapActions, mapGetters } from 'vuex';
import { onePeriodInTwoDays } from "@/util/periodOptions";

export default {
  name: "alarms",
  components:{
    MovementAlarms, 
    CommunicationAlarms,
    GeofencingAlarms
  },
  data() {
    return {
      date: [new Date(Date.now() - 24 * 60 * 60 * 1000), new Date()],
      periodsOptions: onePeriodInTwoDays,
      currentPeriodIndex: 5,
      types: [
        {
          dataType: true,
          title: "All",
        },
        {
          dataType: false,
          title: "Movement",
        },
        {
          dataType: false,
          title: "Geofencing",
        },
        {
          dataType: false,
          title: "Communication",
        },
      ],
      states: [
        {
          title: "Active",
          state: true
        },
        {
          title: "Not Active",
          state: false
        }
      ],
      value: "",
      tabs: [
        {
          title: "active",
          isActive: true,
        },
        {
          title: "not active",
          isActive: false,
        },
      ],
      active: true,
      currentType: "All"
    };
  },
  computed:{
    ...mapGetters("GlobalMobility/ScooterStore/ScooterAlarms",[
      'data',
      'loading'
    ])
  },
  methods: {
    changeDateType(type) {
      this.types.forEach((tab) => {
        if (type.title == tab.title) {
          tab.dataType = true;
          this.currentType = type.title
        } else {
          tab.dataType = false;
        }
      });
    },
    changeState(state) {
      this.states.forEach((tab) => {
        if (state.title == tab.title) {
          tab.state = true;
        } else {
          tab.state = false;
        }
      });
    },
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        if (tab.title == selectedTab.title) {
          tab.isActive = true;
        } else {
          tab.isActive = false;
        }
      });
    },
    ...mapActions('GlobalMobility/ScooterStore/ScooterAlarms', [
      'fetchAlarmsData',
    ]),
    fetchData(){
      const payload = {
        id: this.scooterID,
        start: this.date[0].toISOString(),
        end: this.date[1].toISOString()
      }
      this.fetchAlarmsData(payload)
    }
  },
  watch: {
    currentPeriodIndex(val) {
      this.date[0] = this.periodsOptions[val].from().toDate();
      this.date[1] = this.periodsOptions[val].to().toDate();
      this.fetchData();
    }
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchData()
  }
};
</script>

<style lang="scss"  scoped>
$primary: #42b883;
.card {
  border-radius: 3px !important;
  height: 350px
}
.card .btn-tab {
  margin-right: -2px;
  border-radius: 1px;
  height: 35px;
}

.active {
  color: $primary;
  border-bottom: 3px solid $primary;
}

</style>