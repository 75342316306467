<template>
  <div class="font-color-1">
    <div class="chartContainer pt-2" style="height: 200px">
      <zingchart
        ref="chart"
        v-if="config"
        :data="config"
        style="text-transform: capitalize; width: 400px !important"
        :height="'275px'"
        :width="'100%'"
        dir="ltr"
      ></zingchart>
    </div>
    <div style="margin-top: -80px">
      <h3 class="m-0" style="text-transform: none" v-if="value">{{ this.value }} {{ this.unit }}</h3>
      <h3 class="m-0" style="text-transform: none" v-else>
        Less than <br> 1 km
      </h3>
    </div>
  </div>
</template>

<script>
  import zingchart from 'zingchart/es6'

  const drawValue = function(p, value, inside = false) {
    var graph = zingchart.exec(p.id, 'getobjectinfo', {
      object : 'graph',
      name : '0'
    });
    var info = zingchart.exec(p.id, 'getobjectinfo', {
      object : 'scale',
      name : 'scale-r'
    });

    if (!info) return

    var center = [info.x + info.width/2, info.y + info.height/2];
    var angles = [270 - info.aperture/2, 270 + info.aperture/2];
    var ratio = (value - info.minValue) / (info.maxValue - info.minValue);
    var angle = angles[0] + info.aperture * ratio;
    var r = 0.85 * Math.min(info.width, info.height) / 2;
    var scale = inside ? 0.8 : 1.6

    var xy = [
      center[0] + r * scale * Math.cos(angle * 2 * Math.PI / 360) - graph.x,
      center[1] + r * scale * Math.sin(angle * 2 * Math.PI / 360) - graph.y
    ];
    zingchart.exec(p.id, 'addobject', {
      type : 'label',
      data : {
        text : value,
        fontColor: '#999',
        anchor : 'c',
        x : xy[0],
        y : xy[1]
      }
    });
  }

  export default {
    name: 'speedometer',
    props: {
      ranges: { type: Array, default: () => [] },
      value: { type: Number, default: 0 },
      tickValue: { type: Number, default: 0 },
      minValue: { type: Number, default: 0 },
      maxValue: { type: Number, default: 100 },
      unit: { type: String, default: 'km/h'}
    },
    data: () => ({
      chartID: ''
    }),
    mounted() {
      this.chartID = this.$refs.chart.$el.id

      zingchart.bind(this.chartID, 'complete', (p) => {
        this.setLabels(this.chartID, this.ranges)
      });

      this.setLabels(this.chartID, this.ranges)
    },
    methods: {
      getConfigGauge(ranges, configs = {}) {
        const getValue = (value) =>
          value < this.minValue ? this.minValue :
          value > this.maxValue ? this.maxValue :
          value

        const config = {
          backgroundColor: "transparent",
          type: "gauge",
          plotarea: {
            marginTop: 50,
            marginLeft: 40,
            marginRight: 40
          },
          scale: {
            sizeFactor: '120%'
          },
          plot: {
            size: "100%",
            valueBox: {
              placement: "center",
              text: "",
              color: "white",
              borderRadius: "30%",
              fontSize: 200,
            },
            backgroundColor: "#000000",
          },
          tooltip: {
            borderRadius: 150,
            text: "%t",
          },
          scaleR: {
            aperture: 180,
            minValue: this.minValue,
            maxValue: this.maxValue,
            itemsOverlap: true,
            center: {
              visible: false,
            },
            tick: {
              visible: true,
              size: 40,
              lineColor: this.isDarkMode ? '#27293d' : '#f9f9f9',
              lineWidth: 1
            },
            ring: {
              backgroundColor: "#42b883",
              size: 40,
            },
          },
          series: [
            {
              values: [getValue(this.value)],
              indicator: [4, 4, 10, 10, 0.70],
              borderRadius: 0,
              backgroundColor: "#ff0000",
              size: "100%",
              text: this.value ? this.value.toString() : "",
              animation: {
                effect: 2,
                method: 1,
                sequence: 4,
                speed: 900,
              },
            },
            {
              values: [getValue(this.tickValue)],
              indicator: [1, 1, 10, 10, 0.65],
              borderRadius: 0,
              backgroundColor: "#ff0000",
              size: "140",
              offsetR: 100,
              text: this.tickValue ? this.tickValue.toString() : "",
              animation: {
                effect: 2,
                method: 1,
                sequence: 4,
                speed: 900,
              },
            },
          ],
        };

        config.scaleR.item = {
          offsetR: 0,
          fontSize: 10,
          visible: ranges.length ? false : true,
        }

        update(config, configs);

        return config;

        function update(obj, newObj) {
          for (let prop in newObj) {
            let val = newObj[prop];

            if (typeof val == "object") update(obj[prop], val);
            else obj[prop] = val;
          }
        }
      },
      setLabels(chartID, ranges) {
        const minValue = Math.min(...ranges)
        const maxValue = Math.max(...ranges)

        const getPercent = (val) => {
          let from = minValue, to = maxValue

          to -= from

          return (val / to) * 100
        };

        let prevRangeClose = false, prevValue = 0

        if (ranges.length) {
          drawValue({id: chartID}, ranges[0])
          prevValue = ranges[0]

          ranges.forEach((value, i) => {
            if (value != prevValue) {
              let diff = Math.abs(value - prevValue)
              prevRangeClose = !prevRangeClose ? getPercent(diff) < 5 : false
            }

            drawValue({id: chartID}, value, prevRangeClose)
            prevValue = value
          })
        }
      }
    },
    computed: {
      config() {
        return this.getConfigGauge(this.ranges)
      },
      isDarkMode() {
        return this.$store.getters.isDarkMode;
      },
    },
  };
</script>

<style scoped>
  div.chartContainer {
    margin: auto;
    display: flex;
    justify-content: center;
  }
</style>