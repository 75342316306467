<template>
<card class="h-100 attendance">
    <div class="row">
      <div class="col-12">
          <h4 class="m-0">Attendance</h4>
      </div>
      
      <div class="position-absolute top-1 right-1">
          <div class="d-flex align-items-start">
          <inline-svg :src="require('@/assets/Icons/worker.svg')" width="40" height="40" class="checked-in-att"  />
          <span class="display-3 d-inline-block">25</span>
          </div>
      </div>
      
      
    </div>


    <div class="mt-5">

        <!--  -->

        <div class="mb-4">
            <div class="d-flex justify-content-start align-items-center mb-2">
              <inline-svg :src="require('@/assets/Icons/worker.svg')" width="40" height="40" class="checked-in-att"  />
              <span class="display-3 ml-2 mr-2">60</span>
              <span>Checked In</span>
            </div>
            <div class="percent">
              <div style="width:60%;background-color:#00aee4"></div>
            </div>
        </div>

        <div class="mb-4">
            <div class="d-flex justify-content-start align-items-center mb-2">
              <inline-svg :src="require('@/assets/Icons/worker.svg')" width="40" height="40" class="checked-out-att"  />
              <span class="display-3 ml-2 mr-2">20</span>
              <span>Checked Out</span>
            </div>
            <div class="percent">
              <div style="width:20%;background-color:#f64860"></div>
            </div>
        </div>
        

      


    </div>
  </card>
    
</template>
<script>
export default {
    
}
</script>
<style scoped>
 


.percent {
  height: 30px;
  width: 100%;
  background-color:transparent;
}
.percent div {
  height: 30px;
  border-radius: 3px;
}


</style>
<style >
    .checked-in-att path{
        fill:#00aee4;
    }
    .checked-out-att path{
      fill:#f5455a;
    }

</style>