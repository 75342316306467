<template>
    <card>
        <el-table :data="tableData" :span-method="objectSpanMethod">
            <template v-for="(column, index) in columns">
                  <el-table-column
                          :key="index"
                          v-bind="column"
                          align="center"
                          v-if="column.customTemplate"
                  >
                      <template slot-scope="{ row }">
                          <template v-if="column.prop=='deviceName'">
                              <span>{{ getDeviceName(row) }}</span>
                          </template>
                          <template v-if="column.prop=='nvrDetails'">
                              <span>{{ getDeviceDetails(row) }}</span>
                          </template>
                          <template v-if="column.prop=='nvrAlarmsEmail'">
                              <span>{{ getDeviceAlarmsEmail(row) }}</span>
                          </template>
                      </template>
                  </el-table-column>
                  <el-table-column 
                      v-else
                      :key="index"
                      v-bind="column"
                  />
            </template>
        </el-table>
    </card>
</template>

<script>
export default {
    props: {
        columns: {},
        data: {}
    },
    computed: {
        tableData() {
            let result = [];
            if(!this.data || !this.data.devices) {
              return result;
            }
            this.data.devices.forEach(device => {
              if(device.cameras) {
                device.cameras.forEach(camera => {
                  result.push({
                    cameraName:camera.name,
                    channel:camera.channelId,
                    cameraDetails: camera.description,
                    type: camera.type,
                    alarmEmail: camera.alarmEmail,
                    off: camera.off ? 'off' : 'on',
                    parentId: camera.parentId,
                  });
                })
              }
            });
            return result;
        }
    },
    methods: {
        getDeviceName(row) {
            let deviceId = row.parentId;
            return this.data.devices.find(device => device.id == deviceId).name;
        },
        getDeviceDetails(row) {
          let deviceId = row.parentId;
          let description = this.data.devices.find(device => device.id == deviceId).description;
          return (description ? description : '--');
        },
        getDeviceAlarmsEmail(row) {
          let deviceId = row.parentId;
          return this.data.devices.find(device => device.id == deviceId).alarmEmail;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let deviceId = this.tableData[rowIndex].parentId;
            let camerasCount = this.data.devices.find(device => device.id == deviceId).cameras.length;
            if (columnIndex === 0) {
              if(rowIndex % camerasCount === 0) {
                return {
                  rowspan: camerasCount,
                  colspan: 1,
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0,
                };
              }
            }
        }
    },
}
</script>

<style>

</style>