<template>
  <card>
    <template slot="header">
      <div class="d-flex align-tiems-center justify-content-between">
        <h4 class="font-color-4 font-weight-bold mb-0">Permissions</h4>
        <base-button style="border-radius: 1px" class="btn btn-primary">Add</base-button>
      </div>
    </template>
    <el-table :data="data" class="striped-table">
      <el-table-column prop="rule_name" label="Rule Name" min-width="100px"  align="center"/>
      <el-table-column prop="name" label="Name" min-width="100px" align="center" />
      <el-table-column prop="permissions" label="Permissions" min-width="100px" align="center" />
      <el-table-column prop="modules" label="Modules" min-width="150px"  align="center"/>
      <el-table-column min-width="150px" label="Action" align="center">
        <template slot-scope="{}">
          <div class="d-flex align-items-center justify-content-center">
            <inline-svg class="mx-1" style="cursor: pointer" width="30" height="30" :src="require('@/assets/Icons/smartlock/preview-24px.svg')" />
            <inline-svg @click="showEditPermission" class="mx-1" style="cursor: pointer" width="30" height="30" :src="require('@/assets/Icons/smartlock/border_color-24px.svg')" />
            <inline-svg class="mx-1" style="cursor: pointer" width="30" height="30" :src="require('@/assets/Icons/smartlock/delete-24px.svg')" />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <TablePagination
      slot="footer"
      ref="paginator"
      :total="10"
      @change="updatePagination"
    ></TablePagination>
    <Modals :editPopupVisible="editPopupVisible" @close="closeEditPopupVisible" />

  </card>
</template>

<script>
import TablePagination from "@/components/TablePagination.vue";
import Modals from './components/Modals'

export default {
  name: "permissions",
  components:{
    TablePagination,
    Modals
  },
  data(){
    return{
      data: [
        {
          rule_name: "1234",
          name: "Info",
          permissions: "Info",
          modules: "Info",
        },
        {
          rule_name: "1234",
          name: "Info",
          permissions: "Info",
          modules: "Info",
        },
        {
          rule_name: "1234",
          name: "Info",
          permissions: "Info",
          modules: "Info",
        },
        {
          rule_name: "1234",
          name: "Info",
          permissions: "Info",
          modules: "Info",
        },
        {
          rule_name: "1234",
          name: "Info",
          permissions: "Info",
          modules: "Info",
        },
        {
          rule_name: "1234",
          name: "Info",
          permissions: "Info",
          modules: "Info",
        },
      ],
      pagination:{
        offset: 0,
        limit: 5
      },
      editPopupVisible: false
    }
  },
  methods:{
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
    }, 
    showEditPermission(){
      this.editPopupVisible = true;
    },
    closeEditPopupVisible(){
      this.editPopupVisible = false
    },
  }
}
</script>
