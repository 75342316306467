<template>
  <card v-if="!loading">
    <div class="row" v-if="data">
      <div class="col-lg-5">
        <div class="col-lg-11">
          <span class="title" style="width: 100%; font-size: 24px">
            <img
              :src="require('@/assets/Icons/forklift/report-main.svg')"
              class="mr-3"
            />
            Create Service Request
            <el-popover
              placement="top-start"
              title=".."
              width="300"
              height="400"
              trigger="hover"
              content="..."
            >
              <img
                slot="reference"
                :src="require('@/assets/Icons/forklift/report-service.svg')"
                class="ml-4"
              />
            </el-popover>
          </span>
          <!-- <el-popover
              placement="bottom"
              width="400"
              trigger="manual"
              v-model="dropdownVisible"
            >
              <li class="nav-link">
                <router-link to="/profiles/user/me" class="nav-item dropdown-item">Profile</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li class="nav-link">
                <a href="#" @click="logout" class="nav-item dropdown-item">Log out</a>
              </li>
            </el-popover> -->
          <card class="mt-3">
            <span class="title">Vechile: </span>
            <p class="value">{{ data.vehicle.name }}</p>
            <span class="title">Plate No: </span>
            <p class="value">{{ data.vehicle.registration_plate }}</p>
            <span class="title">Driver: </span>
            <p class="value">{{ data.driver.name }}</p>
            <span class="title">Contact: </span>
            <p class="value">{{ data.driver.phone }}</p>
          </card>
        </div>
        <div class="col-lg-11">
          <card>
            <div>
              <span class="title">Issue:</span>
              <p>{{ data.issue }}</p>
            </div>
            <div class="mt-2">
              <span class="title">Description: </span>
              <p>{{ data.description }}</p>
            </div>
          </card>
        </div>
        <div class="col-lg-11">
          <span style="font-size: 19px"><b>Additional Comments:</b></span>
          <card style="min-height: 150px" class="mt-2">
            {{ data.driver_comment }}
          </card>
        </div>
        <div class="col-lg-11 mt-5">
          <card>
            <span class="title">Selected Autoshop: </span>
            <p class="value">{{ data.autoshop.name }}</p>
            <span class="title">Selected date: </span>
            <p class="value">{{ data.due_date.substring(0, 10) }}</p>
          </card>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row mb-5">
          <ul class="list-unstyled text-center header-card w-100 pr-5">
            <li class="ml-5 mr-3 mt-2"><b>Current Status:</b></li>
            <li class="dot mr-4">
              <span class="bg-success"></span>
              Repaired
            </li>
          </ul>
        </div>
        <slider
          v-if="items.length"
          :items="items"
          :countOfElementShown="5"
          :countOfElementHiddenPrev="5"
          :countOfElementHiddenNext="5"
          :centerElementNextIndex="4"
          :lastItem="items.length - 1"
          :imgHight="550"
          :imgIcon="1"
        >
          <template #indicatorArea="{ item }">
            <div class="slide-cover">
              <span class="char">{{ item.errorSide[0].toUpperCase() }}</span>
              <inline-svg  :src="item.img"></inline-svg>

            </div>
          </template>
          <template #sliderArea="{ item }">
            <inline-svg  class="slide-img" :src="item.img"></inline-svg>
          </template>
        </slider>

        <div class="row mt-5 pr-5">
          <ul
            class="list-unstyled d-flex justify-content-end align-items-center text-right w-100"
          >
            <li class="mr-2"><b>Cost:</b></li>
            <li class="mr-5 ml-3">
              <!-- <card bodyClasses="text-black-50 bg-secondary" class="mb-0"><b>{{ data.cost }}</b></card> -->
              <card bodyClasses="text-black-50 bg-secondary" class="mb-0"
                ><b>800$</b></card
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { DatePicker, Select, Option } from "element-ui";
import Slider from "@/components/Slider/Slider";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "view",
  data() {
    return {
      options: [
        {
          value: "all",
          label: "All",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      selectedAutoshop: "",
      items: [
        {
          img: require("@/assets/Icons/forklift/slider_img/after_repair.svg"),
          errorSide: "Right",
          id: 0,
          selected: false,
          error: true,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_2.svg"),
          errorSide: "Left",
          id: 1,
          selected: false,
          error: false,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_3.svg"),
          errorSide: "Front",
          id: 2,
          selected: false,
          error: false,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_4.svg"),
          errorSide: "D",
          id: 3,
          selected: false,
          error: false,
        },
        {
          img: require("@/assets/Icons/forklift/slider_img/slide_5.svg"),
          errorSide: "Back",
          id: 4,
          selected: false,
          error: false,
        },
      ],
      forkID: "",
      date: "",
    };
  },
  components: {
    DatePicker,
    Select,
    Option,
    Slider,
  },
  computed: {
    ...mapGetters("ForkliftStore/Report", ["data", "autoshopData", "loading"]),
  },
  watch: {
    data: {
      handler() {
        this.selectedAutoshop = this.data.autoshop.id;
        this.date = this.data.due_date;
        if (this.data) {
          this.items.map((item) => {
            if (item.errorSide == this.data.side) {
              console.log(this.data.side);
            }
          });
        }
      },
    },
  },
  methods: {
    ...mapActions("ForkliftStore/Report", ["fetchData", "getAutoshops"]),
  },
  created() {
    this.forkID = this.$route.params.id;
    this.fetchData(this.forkID);
    this.getAutoshops();
  },
  // beforeDestroy() {
  //   this.$store.commit("ForkliftStore/Report/setData", []);
  // },
};
</script>

<style lang="scss" scoped>
.title {
  width: 40%;
  display: inline-block;
  font-size: 1.07rem;
  margin-bottom: 10px;
}
.value {
  width: 60%;
  display: inline-block;
}
// Slider Contain
.header-card li {
  display: inline-block;
  font-size: 18px;
  border: none;
}
.header-card li.bg span {
  padding: 5px 10px;
  display: inline-block;
}
.header-card li.dot span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.icon-cover svg {
  filter: inherit !important;
}
.icon-cover.active .circul {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}
.slide-cover {
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
  border: 1px solid transparent !important;
  border-radius: 5px;
}
.span-active {
  border: 1px solid #42b883 !important;
}
.icon-cover .char {
  position: absolute;
  top: -10px;
  left: -10px;
  background: #f5f5f5;
  color: #484848;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 6px;
  font-weight: bold;
  box-shadow: 0px 1px 5px 0px #333333b8;
}
.icon-cover.hidden .char {
  visibility: hidden;
}
.slide-img {
  height: 65%;
  width: 65%;
  top: 45%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
</style>