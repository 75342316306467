<template>
  <div class="row">
    <div class="col-md-12 w-100">
      <canvas id="canvas"></canvas>
      <div
        id="viewerProgressDiv"
        style="
          position: absolute;
          top: 50%;
          left: 32%;
          background-color: rgba(128, 128, 128, 0.7);
          padding: 20px;
          border-radius: 25px;
        "
      >
        <progress
          id="viewerProgress"
          class="viewerComponent"
          value="0"
          max="100"
          style="width: 400px"
        ></progress>
        <label
          id="viewerProgressLabel"
          class="viewerComponent"
          style="margin-top: 2px; display: block; margin-left: 10px;color: rgb(30 30 42);"
          >Loading...</label
        >
      </div>
      <button class="btn" id="infobutton">info</button>
    </div>
  </div>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader2 } from "three/examples/jsm/loaders/OBJLoader2.js";
import { DragControls } from "three/examples/jsm/controls/DragControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { MtlObjBridge } from "three/examples/jsm/loaders/obj2/bridge/MtlObjBridge.js";
import { Interaction } from "three.interaction";
import Slider from "@/components/Slider.vue";
export default {
  components: {
    Slider,
  },
  data() {
    return {
      value: 0,
      range: {
        min: 0,
        max: 10,
      },
    };
  },

  mounted() {

    
    let canvas = document.getElementById("canvas");
    fitToContainer(canvas);
    function fitToContainer(canvas) {
      // Make it visually fill the positioned parent
      canvas.style.width = "100%";
      canvas.style.height = "400px";
      // ...then set the internal size to match
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    }
    var progress = document.getElementById("viewerProgress");
    var progressDiv = document.getElementById("viewerProgressDiv");
    var infobutton = document.getElementById("infobutton");
    var home3d = null;
    var securityCams = [];
    var scene,
      camera,
      renderer,
      orbit,
      transControls,
      rotObjectMatrix,
      interaction,
      dragcontrols;
    var group = new THREE.Group();
    var seleced_camera = null;

    main();

    function main() {
      var manager = new THREE.LoadingManager();
      manager.onStart = function (item, loaded, total) {
        //console.log("Loading started");
      };

      manager.onLoad = function () {
        console.log("Loading complete");
        progressDiv.style.visibility = "hidden";
      };

      manager.onProgress = function (item, loaded, total) {
        // console.log(item, loaded, total);
        // console.log("Loaded:", Math.round((loaded / total) * 100, 2) + "%");
        progress.value = Math.round((loaded / total) * 100, 2);
      };

      manager.onError = function (url) {
        console.log("Error loading");
      };
      const mtlLoader = new MTLLoader(manager);
      mtlLoader.load(
        "home3d/SweetHome3DExample3.mtl",
        (mtlParseResult) => {
          const objLoader = new OBJLoader2(manager);
          const materials = MtlObjBridge.addMaterialsFromMtlLoader(
            mtlParseResult
          );
          objLoader.addMaterials(materials);
          objLoader.load(
            "home3d/SweetHome3DExample3.obj",
            (root) => {
              home3d = root;
              // const objLoader2 = new OBJLoader2(manager);
              // var ship_material = new THREE.MeshBasicMaterial({
              //   color: 0xffffff,
              // });
              // objLoader2.load(
              //   "Camera_altcam/CCTV_Camera.obj",
              //   (root2) => {
              //     // root2.traverse(function (child) {
              //     //   if (child instanceof THREE.Mesh) {
              //     //     child.material = ship_material;
              //     //   }
              //     // });
              //     for (var i = 0; i < 4; i++) {
              //       let copy = root2.clone();
              //       copy.scale.set(3, 3, 3);
              //       copy.rotation.y = i + 1 * 1000;
              //       copy.position.x = i + 2;
              //       copy.camera_id = i;
              //       securityCams.push(copy);
              //     }
              //     init();
              //     render();
              //   }
              // );

              mtlLoader.load(
                "low-poly/LowPoly CCTV_Camera.mtl",
                (secParseResult) => {
                  const objLoader2 = new OBJLoader2(manager);
                  const materials2 = MtlObjBridge.addMaterialsFromMtlLoader(
                    secParseResult
                  );
                  objLoader2.addMaterials(materials2);
                  objLoader2.load(
                    "low-poly/LowPoly CCTV_Camera.obj",
                    (root2) => {
                      console.log(root2);
                      for (var i = 0; i < 4; i++) {
                        let copy = root2.clone();
                        copy.scale.set(220, 220, 220);
                        //copy.rotation.y = i + 1 * 100;
                        copy.position.z = -32 * i;
                        copy.position.y = -45;
                        copy.camera_id = i;
                        securityCams.push(copy);
                      }

                      init();
                      render();
                    }
                  );
                }
              );
            }
          );
        }
      );
    }

    function init() {
      scene = new THREE.Scene();
      scene.add( new THREE.GridHelper( 10000, 100, 0x888888, 0x444444 ) );
      camera = new THREE.PerspectiveCamera(
        75,
        canvas.width / canvas.height,
        0.1,
        1000
      );

      renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });

      interaction = new Interaction(renderer, scene, camera);

      orbit = new OrbitControls(camera, renderer.domElement);
      orbit.target.set(0, 5, 0);
      orbit.update();
      transControls = new TransformControls(camera, renderer.domElement);
      transControls.addEventListener("dragging-changed", function (event) {
        orbit.enabled = !event.value;
      });
      scene.add(transControls);

      

      {
        const skyColor = 0xb1e1ff; // light blue
        const groundColor = 0xb97a20; // brownish orange
        const intensity = 0.7;
        const light = new THREE.HemisphereLight(
          skyColor,
          groundColor,
          intensity
        );
        scene.add(light);
      }
      {
        const color = 0xffffff;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(5, 10, 2);
        scene.add(light);
        scene.add(light.target);
      }

      group.add(home3d);
      securityCams.forEach((cam) => {
        cam.cursor = "pointer";
        cam.on("mousedown", function (e) {
          console.log(e);
         
          e.stopPropagation()
          seleced_camera = e.data.target;
          transControls.attach(seleced_camera);
        });

        group.add(cam);
      });

      

      infobutton.addEventListener("click", showcamsinfo, false);
      window.addEventListener("keydown", function (event) {
        switch (event.keyCode) {
          case 81: // Q
            transControls.setSpace(
              transControls.space === "local" ? "world" : "local"
            );
            break;

          case 16: // Shift
            transControls.setTranslationSnap(100);
            transControls.setRotationSnap(THREE.MathUtils.degToRad(15));
            transControls.setScaleSnap(0.25);
            break;

          case 87: // W
            transControls.setMode("translate");
            break;

          case 69: // E
            transControls.setMode("rotate");
            break;

          case 82: // R
            transControls.setMode("scale");
            break;

          case 187:
          case 107: // +, =, num+
            transControls.setSize(control.size + 0.1);
            break;

          case 189:
          case 109: // -, _, num-
            transControls.setSize(Math.max(control.size - 0.1, 0.1));
            break;

          case 88: // X
            transControls.showX = !control.showX;
            break;

          case 89: // Y
            transControls.showY = !control.showY;
            break;

          case 90: // Z
            transControls.showZ = !control.showZ;
            break;

          case 69: // E
            transControls.setMode("rotate");
            break;
          case 32: // Spacebar
            transControls.enabled = !transControls.enabled;
            break;
          case 27: // Spacebar
            transControls.detach(seleced_camera) ;
            break;
        }
      });

      scene.add(group);
      const box = new THREE.Box3().setFromObject(home3d);
      const boxSize = box.getSize(new THREE.Vector3()).length();
      const boxCenter = box.getCenter(new THREE.Vector3());

      // set the camera to frame the box
      frameArea(boxSize * 1.2, boxSize, boxCenter);
      orbit.maxDistance = boxSize * 10;
      orbit.target.copy(boxCenter);
      
      orbit.update();
    }

    // obj locaion info
    function showcamsinfo() {
      securityCams.forEach((cam) => {
        console.log("cam", cam);
      });
    }

    function frameArea(sizeToFitOnScreen, boxSize, boxCenter) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
      const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      // compute a unit vector that points in the direction the camera is now
      // in the xz plane from the center of the box
      const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .multiply(new THREE.Vector3(1, 0, 1))
        .normalize();

      // move the camera to a position distance units way from the center
      // in whatever direction the camera was from the center already
      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

      // pick some near and far values for the frustum that
      // will contain the box.
      camera.near = boxSize / 100;
      camera.far = boxSize * 100;

      camera.updateProjectionMatrix();

      // point the camera to look at the center of the box
      camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    }

    function render() {
      renderer.render(scene, camera);
      requestAnimationFrame(render);
    }
  },
};
</script>
<style scoped>
</style>