<template>
  <el-dialog title="Edit Permission" width="600px" :visible.sync="show" custom-class="edit-permission">
    <div class="px-5">
      <span class="h4 font-weight-bold">Secondary Supervisor 1</span>
      <el-collapse v-model="activeNames" @change="handleChange" class="mt-4">
        <el-collapse-item :name="i" v-for="(collapse,i) in collapses" :key="i">
          <template slot="title">
            <div class="d-flex align-items-center justify-content-between w-100">
              <span class="font-color-4 font-weight-bold h5 mb-0" >{{collapse.title}}</span>
              <span class="font-weight-bold h5 mb-0 mr-5">{{collapse.total}}</span>
            </div>
          </template>
          <div class="row px-1">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3" v-for="(item,i) in details" :key="i">
              <base-checkbox v-model="item.visible" :checked="item.visible" class="mx-2">
                <span class="font-weight-bold">{{ item.label }}</span>
              </base-checkbox>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-4 ">
      <base-button style="border-radius: 1px" class="btn btn-primary btn-simple" @click="close">Cancel</base-button>
      <base-button style="border-radius: 1px" class="btn btn-primary ">Submit</base-button>
    </div>
  </el-dialog>
</template>

<script>
import { Collapse, CollapseItem} from 'element-ui'
export default {
  name: "create-quicke-access",
  components:{
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  props:{
    isOpen: {
        type: Boolean
    },
    type:{
      type: String
    }
  },
  data(){
    return{
      activeNames: ['1'],
      options: [],
      value: "",
      checked: "Yes",
      collapses:[
        {title: "Locks", total: 2},
        {title: "Alert Settings", total: 0},
        {title: "Quick Access", total: 0},
        {title: "Scheduled Access", total: 0},
        {title: "Permissions", total: 0},
      ],
      details: [
        {
          label: "View",
          visible: false
        },
        {
          label: "Add",
          visible: false
        },
        {
          label: "Edit",
          visible: true
        },
        {
          label: "Delete",
          visible: true
        },
      ],
      start_date: "",
      end_date: "",
    }
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>

<style >
.white-content .edit-permission{
  background: white !important 
}
.edit-permission .date-class{
  width: 50% !important
}
</style>