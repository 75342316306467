<template>
  <card class="card" v-loading="loading">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <h4 class="ml-2 font-color-1 font-weight-bold">Battery</h4>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4">
        <span class="font-color-1 font-weight-bold">Current</span>
        <div style="width: 75%">
          <base-progress
            style="flex: 1 auto"
            :value="data.level_percentage"
            :showValue="false"
            status="good"
            valuePosition="right" />
        </div>
        <span class="font-weight-bold">{{data.level_percentage}}%</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4">
        <span class="font-color-1 font-weight-bold">overall</span>
        <div style="width: 75%">
          <base-progress
            style="flex: 1 auto"
            :value="data.life_percentage"
            status="poor"
            :showValue="false"
            valuePosition="right" />
        </div>
        <span class="font-weight-bold">{{data.life_percentage}}%</span>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-4 pb-3" v-if="data.cycles">
        <span class="font-color-1 font-weight-bold">{{data.cycles.left}}/{{data.cycles.total}} Cycles left</span>
        <span class="font-color-1 font-weight-bold">{{millisToReadable(data.life_time_left)}}</span>
      </div>
    </template>
  </card>
</template>

<script>
import BaseProgress from '@/components/BaseProgress';
import { mapActions, mapGetters } from 'vuex';
import { getDurationString, millisToReadable } from '@/util/helper';

export default {
  name: "battery",
  components:{
    BaseProgress,
  },
  data(){
    return{
      value: 60.3
    }
  },
  computed:{
    ...mapGetters("GlobalMobility/ScooterStore/ScooterBattery",[
      'data',
      'loading'
    ])
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore/ScooterBattery', [
      'fetchBatteryData'
    ]),
    getDurationString,
    millisToReadable
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchBatteryData(this.scooterID)
  }
}
</script>

<style scoped>
.card{
  border-radius: 3px !important;
}
</style>