<template>
  <card class="h-100 employess">
    <div class="row align-items-center">
      <div class="col-md-2 col-lg-2 col-sm-3">
        <h4 class="m-0">Employess</h4>
      </div>
       <div class="col-lg-4 col-md-4 col-sm-6">
        <base-select class="mw-50"  v-model="duration" :options="durationOptions"></base-select>
      </div>
    </div>

    <div class="mt-4 data-table">
      <table class="table custom">
        <thead>
          <tr>
            <th></th>
            <th >
              <div class="d-inline-block text-center">
                <inline-svg
                  :src="require('@/assets/Icons/noun_person_2005118.svg')"
                  width="20"
                  height="20"
                  class="staff-icon mb-2"
                />
                <span class="d-block">Staff Name</span>
              </div>
            </th>
            <th class="text-center">
              <div class="d-inline-block text-center">
                <inline-svg
                  :src="require('@/assets/Icons/attendance.svg')"
                  width="20"
                  height="20"
                  class="attendance-icon mb-2"
                />
                <span class="d-block">% Of Attendance</span>
              </div>
            </th>
            <th class="text-center">
              <div class="d-inline-block text-center">
                <inline-svg :src="require('@/assets/Icons/checkin.svg')" width="20" height="20" class="checked-in-icon mb-2" />
                <span class="d-block">Checked In</span>
              </div>
            </th>
            <th class="text-center">
              <div class="d-inline-block text-center">
                <inline-svg
                  :src="require('@/assets/Icons/checkout.svg')"
                  width="20"
                  height="20"
                  class="checkout-icon mb-2"
                />
                <span class="d-block">Checked Out</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd">
            <td>
              <img src="img/default-avatar.png" class="rounded-circle" alt="Table image" />
            </td>
            <td class="first">dda</td>
            <td class="text-center">95%</td>
            <td class="text-center">28/1/2020 11:06</td>
            <td class="last text-center">28/1/2020 11:06</td>
          </tr>
          <tr>
            <td>
              <img src="img/default-avatar.png" class="rounded-circle" alt="Table image" />
            </td>
            <td class="first">dda</td>
            <td class="text-center">76%</td>
            <td class="text-center">28/1/2020 11:06</td>
            <td class="last text-center">28/1/2020 11:06</td>
          </tr>
          <tr class="odd">
            <td>
              <img src="img/default-avatar.png" class="rounded-circle" alt="Table image" />
            </td>
            <td class="first">dda</td>
            <td class="text-center">99%</td>
            <td class="text-center">28/1/2020 11:06</td>
            <td class="last text-center">28/1/2020 11:06</td>
          </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>
<script>
export default {
    data(){
    return{
      duration: 1,
      durationOptions: [
        {
          value: 1,
          label: "Area 1",
        },
      ],
    }
  }
};
</script>
<style scoped>
.data-table{
    overflow-x: auto;
}
.employess img {
  max-width: 40px;
}

.employess .custom td {
  border: none !important;
  padding: 0.2em 1em;
}

.employess .custom .odd td:not(:first-child) {
  background-color: #2f324a;
}
.first {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.employess .custom td:first-child {
  width: 3%;
}
</style>
<style >
.attendance-icon path {
  fill: #00ced4;
}
.staff-icon path {
  fill: #fce448;
}
.checked-in-icon path{
    fill:#43fb22;
}
.checkout-icon path,.checkout-icon rect{
  fill:#f5455a;
}

</style>