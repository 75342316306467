<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{id ? 'Update':'Create'}} Workorder Details</h4>
      </div>

      <div>
        <!--  -->

        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-4">
                <base-input :placeholder="$t('Job Name')" v-model="$v.title.$model">
                  <template v-if="$v.title.$error" v-slot:error>
                    <label class="error" v-if="!$v.title.required">{{$t("requireds.Job Name")}}</label>
                  </template>
                </base-input>
              </div>
              <div class="col-md-4">
                <base-input 
                    :placeholder="$t('Quantity')"
                    type="number"
                     v-model="$v.quantity.$model"
                     :disabled="!!id"
                     >
                  <template v-if="$v.quantity.$error" v-slot:error>
                    <label class="error" v-if="!$v.quantity.required">{{$t("requireds.Quantity")}}</label>
                  </template>
                </base-input>
              </div>
              <div class="col-md-2">
                <base-select v-model="$v.contractduration.years.$model" :options="years">
                  <template v-if="$v.contractduration.years.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.contractduration.years.required"
                    >{{$t("requireds.Year")}}</label>
                    <label
                      class="error"
                      v-if="!$v.contractduration.years.validator"
                    >Must select a value</label>
                  </template>
                </base-select>
              </div>
              <div class="col-md-2">
                <base-select v-model="$v.contractduration.months.$model" :options="monthes">
                  <template v-if="$v.contractduration.months.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.contractduration.months.required"
                    >{{$t("requireds.Month")}}</label>
                  </template>
                </base-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <BaseAutoComplete
                  :placeholder="$t('Device Template Search')"
                  :source="TemplateEndpoint"
                  v-model="$v.devicetemplate.$model"
                >
                  <template v-if="$v.devicetemplate.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.devicetemplate.required"
                    >{{$t("requireds.Device Template")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>


              <div class="col-md-4">
                <!-- <base-select v-model="$v.solution.$model" :options="solutions" >
                  <template v-if="$v.solution.$error" v-slot:error>
                    <label class="error" v-if="!$v.solution.required">{{$t("requireds.Solution")}}</label>
                  </template>
                </base-select> -->
                <base-input  :value="solution" placeholder="Solution" readonly></base-input>
              </div>


              
              <div class="col-md-4">
                <BaseAutoComplete
                  :placeholder="$t('Structure Search')"
                  :source="StructureEndpoint"
                  :resultsDisplay="'structure_name'"
                  v-model="$v.structure.$model"
                >
                  <template v-if="$v.structure.$error" v-slot:error>
                    <label class="error" v-if="!$v.structure.required">{{$t("requireds.Structure")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <BaseAutoComplete
                  :placeholder="$t('Government Authority Search')"
                  :source="AuthorityEndpoint"
                  v-model="$v.governmentauthority.$model"
                >
                  <template v-if="$v.governmentauthority.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.governmentauthority.required"
                    >{{$t("requireds.Government Authority")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>
              <div class="col-md-4">
                <BaseAutoComplete
                  :placeholder="$t('FMC Company Search')"
                  :source="FmcEndpoint"
                  v-model="$v.fmccompany.$model"
                >
                  <template v-if="$v.fmccompany.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.fmccompany.required"
                    >{{$t("requireds.FMC Company")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>
              <div class="col-md-4">
                <BaseAutoComplete
                  :placeholder="$t('AMC Company search')"
                  :source="AmcEndpoint"
                  v-model="$v.amccompany.$model"
                >
                  <template v-if="$v.amccompany.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.amccompany.required"
                    >{{$t("requireds.AMC Company")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <BaseAutoComplete
                  :placeholder="$t('Operator search')"
                  :source="OperatorEndpoint"
                  v-model="$v.operator.$model"
                >
                  <template v-if="$v.operator.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.operator.required"
                    >Operator is required</label>
                  </template>
                </BaseAutoComplete>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">{{$t("Register")}}</base-button>
      </template>
    </card>
  </form>
</template>
<script>
import {
  required,
  //sameAs,
  minLength,
  email
  //numeric
} from "vuelidate/lib/validators";
import moment from "moment";
import BaseAutoComplete from "@/components/BaseAutoComplete.vue";
export default {
  components: {
    BaseAutoComplete
  },
  data() {
    return {
      orderid:null,
      id: null,
      title: "",
      quantity: "",
      contractduration: {
        years: '',
        months: ''
      },
      //solution: "",
      devicetemplate: null,
      structure: null,
      governmentauthority: null,
      fmccompany: null,
      amccompany: null,
      operator:null,

      monthes: [
        { value: "", label: "Month" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" }
      ],
      years: [
        { value: "", label: "Year" },
        { value: "0", label: "0" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" }
      ]
    };
  },
  validations: {
    title: { required },
    quantity: { required },
    contractduration: {
      years: {
        required,
        validator: function(e,value) {
            return !(value.years == 0 && value.months == 0);
        }
      },
      months: {
        required,
        
      }
    },
    //solution: { required },
    devicetemplate: { required },
    structure: { required },
    governmentauthority: { required },
    fmccompany: { required },
    amccompany: { required },
    operator:{required:false}
    //devicemanufacturer: { required }
  },
  computed: {
    solutions() {
      return this.$store.state.solutions.map(solution => ({
        value: solution.id,
        label: solution.name
      }));
    },
    solution(){

      if(this.devicetemplate && this.devicetemplate.selectedObject){
          let solution_id = this.devicetemplate.selectedObject.solution_id;
          let solution = this.solutions.find(sol=>sol.value === solution_id);
          if(solution){
            return solution.label;
          }

      }
      return '';
    }
  },

  // mounted
  mounted() {


    //order id
    this.orderid = this.$route.params.orderid;
    if (/updateLot\/[0-9]/.test(this.$route.path)) {
      

      //order details id
      this.id = this.$route.params.id;


      let list =  this.$store.state.workorderstore.lot_list;


      if(!list){
          this.$router.push({path:'/order'});
          return;
      }

      let order_details = list.find(lot => lot.id == this.id);
      

            if (order_details) {
              let [years, months] = order_details.contract_duration.split("|");

              this.title = order_details.name;
              this.quantity = order_details.quantity;
              this.contractduration = {
                years: years,
                months: months
              };
              //this.solution = order_details.solution.id;
              this.devicetemplate = {
                display: order_details.template.name,
                selectedObject: order_details.template
              };
              this.structure = {
                display: order_details.structure.structure_name,
                selectedObject: order_details.structure
              };
              this.governmentauthority = {
                display: order_details.auth.name,
                selectedObject: order_details.auth
              };
              this.fmccompany = {
                display: order_details.fmc.name,
                selectedObject: order_details.fmc
              };
              this.amccompany = {
                display: order_details.amc.name,
                selectedObject: order_details.amc
              };
               this.operator = order_details.operator ?  {
                display: order_details.operator.name,
                selectedObject: order_details.operator
              } : null;
            }
          
        
    }
  },

  methods: {
    FmcEndpoint(input) {
      return `${this.$api.defaults.baseURL}fmc/search/${input}`;
    },
    AmcEndpoint(input) {
      return `${this.$api.defaults.baseURL}amc/search/${input}`;
    },

    AuthorityEndpoint(input) {
      return `${this.$api.defaults.baseURL}authority/search/${input}`;
    },
    OperatorEndpoint(input) {
      return `${this.$api.defaults.baseURL}operator/search/${input}`;
    },
    TemplateEndpoint(input) {
      return `${this.$api.defaults.baseURL}template/search/${input}`;
    },
    StructureEndpoint(input) {
      return `${this.$api.defaults.baseURL}structures/search/${input}`;
    },


    submit() {

      this.$v.$touch();

      

      if (this.$v.$invalid) {
        return;
      } else {
        let data = {
          name: this.title,
          //order_id: this.orderid,
          structure_id: this.structure.selectedObject.id,
          contract_duration: `${this.contractduration.years}|${this.contractduration.months}`,
          //solution_id: this.solution,
          template_id: this.devicetemplate.selectedObject.id,
          authority_id: this.governmentauthority.selectedObject.id,
          fmc_id: this.fmccompany.selectedObject.id,
          amc_id: this.amccompany.selectedObject.id,
          operator_id : this.operator ? this.operator.selectedObject.id : null,
          quantity: this.quantity
        };


        if(this.id && this.orderid){

              //order details id
            data.id = this.id;
            data.order_id = this.orderid;
            
            this.$api.post(`orders/orderdetails/${this.orderid}`,data).then(res=>{
                console.log('update order details',res)
                 if(res.status === 200){
                            let newLot = res.data.data;
                            this.$store.dispatch('workorderstore/updateLot',newLot)
                            this.$router.push({ path: "/order/list" });
                }

                }).catch(err=>{console.log(err)})
        }else{

            data.order_id = this.orderid;
            
            this.$api.post('orders/orderdetails',data).then(res=>{
                    console.log('create order details',res)
                    if(res.status === 201){
                            let newLot = res.data.data;
                            this.$store.dispatch('workorderstore/addLot',newLot)
                            this.$router.push({ path: "/order/list" });
                    }
            }).catch(err=>{console.log(err)})
        }
        console.log("add work order data:", data);
        
      }
    }
  }
};
</script>
<style>
</style>
