<template>
  <div>
    <div class="row">
      <div class="col-xl-6" style="min-height: 70vh">
        <scooter-map />
      </div>
      <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-12">
            <scooter-header />
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 ">
            <scooter-payment /> 
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="row">
          <div class="col-xl-6">
            <scooter-mile-age />
          </div>
          <div class="col-xl-6">
            <scooter-time />
          </div>
        </div>
      </div>
      <div class="col-xl-6 ">
        <scooter-rentals />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div>
          <scooter-battery></scooter-battery>
        </div>
        <div>
          <scooter-speed></scooter-speed>
        </div>
      </div>
      <div class="col-xl-6">
        <div>
          <scooter-info></scooter-info>
        </div>
        <div>
          <scooter-alarms></scooter-alarms>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <scooter-customer-history></scooter-customer-history>
      </div>
    </div>
  </div>
</template>

<script>
import ScooterMap from './core/Map/index.vue'
import ScooterHeader from './core/Header/index.vue'
import ScooterPayment from './core/Payment/index.vue'
import ScooterRentals from './core/Rentals/index.vue'
import ScooterAlarms from './core/Alarms/index.vue'
import ScooterBattery from './core/Battery/index.vue'
import ScooterCustomerHistory from './core/CustomerHistory/index.vue'
import ScooterInfo from './core/Info/index.vue'
import ScooterSpeed from './core/Speed/index.vue'
import ScooterMileAge from '../components/ScooterMileAge'
import ScooterTime from '../components/ScooterTime'
export default {
  name: "Scooter",
  components:{
    ScooterAlarms,
    ScooterBattery,
    ScooterCustomerHistory,
    ScooterHeader,
    ScooterInfo,
    ScooterMap,
    ScooterRentals,
    ScooterSpeed,
    ScooterPayment,
    ScooterMileAge,
    ScooterTime
  }
}
</script>