<template>
  <card class="h-100 areas">
    <div class="row">
      <div class="col-12">
        <h4 class="m-0">Areas</h4>
      </div>

      <div class="position-absolute top-1 right-1">
        <inline-svg :src="require('@/assets/Icons/subtraction.svg')" width="40" height="40" class="areas-main-icon" />
      </div>
    </div>

    <div class="mt-4">
      <!--  -->
      <div class="row mb-4 mr-1 ml-1 odd p-2">
        <div class="col-8 p-0">
          <div class="d-flex align-items-center">
            <h4 class="m-0">
              <i class="fas fa-square fa-xs mr-2" style="color:#0023ff"></i> Area-1
            </h4>
            <span class="ml-2">
              <inline-svg :src="require('@/assets/Icons/worker.svg')" width="30" height="30" class="worker-icon" />
              
            </span>
            <span class="display-4 m-0 ">5</span>
          </div>
        </div>
        <div class="col-4 text-right p-0">
          <div>
            <inline-svg
              :src="require('@/assets/Icons/settings.svg')"
              width="30"
              height="30"
              class="mr-3 settings-icon"
            />
            <a href="#">
              <i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i>
            </a>
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row mb-4 mr-1 ml-1 p-2">
        <div class="col-8 p-0">
          <div class="d-flex align-items-center">
            <h4 class="m-0">
              <i class="fas fa-square fa-xs mr-2" style="color:#f05c5c"></i> Area-1
            </h4>
            <span class="ml-2 ">
              <inline-svg :src="require('@/assets/Icons/worker.svg')" width="30" height="30" class="worker-icon" />
              
            </span>
            <span class="display-4 m-0 ">5</span>
          </div>
        </div>
        <div class="col-4 text-right p-0">
          <div>
            <inline-svg
              :src="require('@/assets/Icons/settings.svg')"
              width="30"
              height="30"
              class="mr-3 settings-icon"
            />
            <a href="#">
              <i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i>
            </a>
          </div>
        </div>
      </div>

      <!--   -->
      <div class="row mb-4 mr-1 ml-1 odd p-2">
        <div class="col-8 p-0">
          <div class="d-flex align-items-center">
            <h4 class="m-0">
              <i class="fas fa-square fa-xs mr-2" style="color:#3ca271"></i> Area-1
            </h4>
            <span class="ml-2">
              <inline-svg :src="require('@/assets/Icons/worker.svg')" width="30" height="30" class="worker-icon" />
            </span>
            <span class="display-4 m-0 ">5</span>
          </div>
        </div>
        <div class="col-4 text-right p-0">
          <div>
            <inline-svg
              :src="require('@/assets/Icons/settings.svg')"
              width="30"
              height="30"
              class="mr-3 settings-icon"
            />
            <a href="#">
              <i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i>
            </a>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="row mb-4 mr-1 ml-1 p-2">
        <div class="col-8 p-0">
          <div class="d-flex align-items-center">
            <h4 class="m-0">
              <i class="fas fa-square fa-xs mr-2" style="color:#d84fd4"></i> Area-1
            </h4>
            <span class="ml-2">
              <inline-svg :src="require('@/assets/Icons/worker.svg')" width="30" height="30" class="worker-icon" />
              
            </span>
            <span class="display-4 m-0 ">5</span>
          </div>
        </div>
        <div class="col-4 text-right p-0">
          <div>
            <inline-svg
              :src="require('@/assets/Icons/settings.svg')"
              width="30"
              height="30"
              class="mr-3 settings-icon"
            />
            <a href="#">
              <i data-v-4af0aa13 class="tim-icons icon-simple-remove"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>
<script>
export default {};
</script>
<style scoped>
.areas i {
  font-size: 20px;
}
.odd {
  background-color: #2f324a;
  border-radius: 10px;
}
.icon-simple-remove {
  color: #f5465c;
  font-weight: bolder;
}
</style>
<style>
.settings-icon path {
  fill: #a5f560;
}
.worker-icon path{
    fill:#fff;
}
.areas-main-icon path{
  fill: #00ced4;
}
</style>
