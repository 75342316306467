<template>
  <form>
    <card footer-classes="text-right">
      <div slot="header">
        <h4 class="card-title">{{ $t("Work Order Create") }} </h4>
      </div>

      <div>
        <!--  -->
        <div class="row"  >
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <BaseAutoComplete
                  :placeholder="$t('AMC')"
                  :source="AmcEndpoint"
                  v-model="amccompany"
                >
                <template v-if="$v.amccompany.$error" v-slot:error>
                  <label class="error"
                         v-if="!$v.amccompany.required">{{$t('AMC') + ' ' + $t("validations.isRequired")}}</label>
                  </template>
                </BaseAutoComplete>
              </div>

              <div class="col-md-6">
                <base-input :placeholder="$t('address')" v-model="$v.amcaddress.$model" readonly>
                  <template v-if="$v.amcaddress.$error" v-slot:error>
                    <label class="error" v-if="!$v.amcaddress.required">{{ $t('address') + ' ' + $t("validations.isRequired")}}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-input :placeholder="$t('phone')" v-model="$v.amcphone.$model" readonly>
                  <template v-if="$v.amcphone.$error" v-slot:error>
                    <label class="error" v-if="!$v.amcphone.required">{{$t('phone') + ' ' + $t("validations.isRequired")}}</label>
                  </template>
                </base-input>
              </div>

              <div class="col-md-6">
                <base-input :placeholder="$t('companyContactPerson')" v-model="$v.amccontactperson.$model" readonly>
                  <template v-if="$v.amccontactperson.$error" v-slot:error>
                    <label class="error"
                           v-if="!$v.amccontactperson.required">
                      {{ $t('companyContactPerson') + ' ' + $t('validations.isRequired') }}
                    </label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <BaseAutoComplete
                  :placeholder="$t('payingCustomer')"
                  :source="payingCustomerEndpoint"
                  v-model="payingcustomer"
                >
                  <template v-if="$v.payingcustomer.$error" v-slot:error>
                    <label class="error"
                           v-if="!$v.payingcustomer.required">{{ $t('payingCustomer') + ' ' +$t("validations.isRequired")}} </label>
                  </template>
                </BaseAutoComplete>
              </div>
              <div class="col-md-6">
                <base-input :placeholder="$t('payingCustomerAddress')" v-model="$v.payingcustomeraddress.$model" readonly>
                  <template v-if="$v.payingcustomeraddress.$error" v-slot:error>
                    <label class="error"
                           v-if="!$v.payingcustomeraddress.required">{{ $t('payingCustomerAddress') + ' ' + $t('validations.isRequired') }}</label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6">
                <base-input
                  :placeholder="$t('payingCustomerPhone')"
                  v-model="$v.payingcustomerphone.$model"
                  readonly
                >
                  <template v-if="$v.payingcustomerphone.$error" v-slot:error>
                    <label class="error"
                           v-if="!$v.payingcustomerphone.required">{{ $t('payingCustomerPhone') + ' ' + $t('validations.isRequired')}}</label>
                  </template>
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input
                   :placeholder="$t('payingCustomerContactPerson')"
                  v-model="$v.payingcustomercontactperson.$model"
                  readonly
                >
                  <template v-if="$v.payingcustomercontactperson.$error" v-slot:error>
                    <label class="error"
                           v-if="!$v.payingcustomercontactperson.required">
                      
                      {{ $t('payingCustomerContactPerson') + ' ' + $t('validations.isRequired') }}
                    </label>
                  </template>
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="exampleInputPassword1">
                {{$t('attachedWorkOrders')}}:</label>
              <div class="add-plus">
                <a href="#" class="plus-icon" @click="addWorkOrder">
                  <i class="fa fa-plus"></i> {{ $t("instructions.addAttachedWorkOrder") }}
                </a>
              </div>
            </div>
            <template v-for="(workorder,index) in $v.allWorkOrder.$each.$iter">
              <div :key="index">
                <!-- remove -->
                <p v-if="index >0" style="cursor: pointer" @click="removeworkorder(index)">
                  <i class="fa fa-minus-circle"></i>
                  <span style="margin: 0 5px;color:#fff">  {{ $t("instructions.remove") }}</span>
                </p>
                <div class="row">
                  <div class="col-md-4">
                    <base-input
                        :placeholder="$t('workOrderTitle')"
                        v-model="workorder.title.$model"
                      >
                        <template v-if="workorder.title.$error" v-slot:error>
                          <label class="error"
                                 v-if="!workorder.title.required">{{ $t('workOrderTitle') + ' ' + $t('validations.isRequired')}}</label>
                        </template>
                      </base-input>
                  </div>
                  <div class="col-md-4">
                    <base-input
                         type="number"
                         :placeholder="$t('quantity')"
                        v-model="workorder.quantity.$model"
                      >
                        <template v-if="workorder.quantity.$error" v-slot:error>
                          <label class="error"
                                 v-if="!workorder.quantity.required">{{ $t('quantity') + ' ' + $t('validations.isRequired') }}</label>
                        </template>
                      </base-input>
                  </div>
                  <div class="col-md-2">
                        <base-select
                            v-model="workorder.contractduration.years.$model"
                            :options="years"
                        >
                          <template v-if="workorder.contractduration.years.$error" v-slot:error>
                            <label class="error" v-if="!workorder.contractduration.years.required">
                              {{ $t('theYear') + ' ' + $t('validations.isRequired') }}
                            </label>
                          </template>
                        </base-select>
                  </div>
                  <div class="col-md-2">
                        <base-select
                            v-model="workorder.contractduration.months.$model"
                            :options="monthes"
                        >
                        <template v-if="workorder.contractduration.months.$error" v-slot:error>
                          <label class="error" v-if="!workorder.contractduration.months.required">
                            {{ $t('theMonth') + ' ' + $t('validations.isRequired') }}
                          </label>
                        </template>
                        </base-select>
                  </div>
                </div>
                <div class="row">

                  <div class="col-md-4">
                    <BaseAutoComplete
                      :placeholder="$tc('deviceWords.device', 2)"
                      :source="TemplateEndpoint"
                      v-model="workorder.devicetemplate.$model"
                    >
                      <template v-if="workorder.devicetemplate.$error" v-slot:error>
                        <label class="error"
                               v-if="!workorder.devicetemplate.required">
                              {{ $tc('deviceWords.device', 2) + ' ' + $t('validations.isRequired') }} 
                        </label>
                      </template>
                    </BaseAutoComplete>
                  </div>

                  <div class="col-md-4">
                    <base-input  :value="getSolutionName(workorder)" :placeholder="$tc('solutionWords.solution')" readonly></base-input>
                  </div>
                  <div class="col-md-4">
                      <BaseAutoComplete
                      :placeholder="$tc('structureWords.structure')"
                      :source="StructureEndpoint"
                      :resultsDisplay="'structure_name'"
                      v-model="workorder.structure.$model"
                    >
                      <template v-if="workorder.structure.$error" v-slot:error>
                        <label class="error"
                               v-if="!workorder.structure.required">
                          {{ $tc('structureWords.structure') + ' ' + $t('validations.isRequired')}}
                        </label>
                      </template>
                    </BaseAutoComplete>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                     <BaseAutoComplete
                      :placeholder="$t('government')"
                      :source="AuthorityEndpoint"
                      v-model="workorder.governmentauthority.$model"
                    >
                      <template v-if="workorder.governmentauthority.$error" v-slot:error>
                        <label class="error"
                               v-if="!workorder.governmentauthority.required">
                          {{ $t('government') + ' ' + $t('validations.isRequired')}}
                        </label>
                      </template>
                    </BaseAutoComplete>
                  </div>
                  <div class="col-md-4">
                     <BaseAutoComplete
                      :placeholder="$t('FMC')"
                      :source="FmcEndpoint"
                      v-model="workorder.fmccompany.$model"
                    >
                      <template v-if="workorder.fmccompany.$error" v-slot:error>
                        <label class="error"
                               v-if="!workorder.fmccompany.required">
                          {{ $t('FMC') + ' ' + $t('validations.isRequired')}}
                        </label>
                      </template>
                    </BaseAutoComplete>
                  </div>
                  <div class="col-md-4">
                     <BaseAutoComplete
                      :placeholder="$t('AMC')"
                      :source="AmcEndpoint"
                      v-model="workorder.amccompany.$model"
                    >
                      <template v-if="workorder.amccompany.$error" v-slot:error>
                        <label class="error"
                               v-if="!workorder.amccompany.required">
                          {{ $t('AMC') + ' ' + $t('validations.isRequired')}}
                        </label>
                      </template>
                    </BaseAutoComplete>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                        <BaseAutoComplete
                          :placeholder="$t('Operator Search')"
                          :source="OperatorEndpoint"
                          v-model="workorder.operator.$model"
                        >
                          <template v-if="workorder.operator.$error" v-slot:error>
                            <label class="error"
                                   v-if="!workorder.operator.required">
                            {{ $t('operator') + ' ' + $t('operator.isRequired') }}
                            </label>
                          </template>
                        </BaseAutoComplete>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--  -->
      </div>
      <template slot="footer" class="text-right">
        <base-button native-type="submit" @click.prevent="submit" type="primary">
          {{$t("instructions.register")}}
        </base-button>
      </template>
    </card>
  </form>
</template>
<script>
import {
  required,
  //sameAs,
  minLength,
  email
  //numeric
} from "vuelidate/lib/validators";
import moment from "moment";
import { users_types } from "../../store/index";
import BaseAutoComplete from "@/components/BaseAutoComplete.vue";
export default {
  components: {
    BaseAutoComplete
  },
  data() {
    return {
      id:null,
      amccompany: null,
      payingcustomer: null,
      allWorkOrder: [
        {
          title: "",
          quantity: "",
          contractduration: {
            years: '',
            months: ''
          },
          //solution: "",
          devicetemplate: null,
          structure: null,
          governmentauthority: null,
          fmccompany: null,
          amccompany: null,
          operator:null
        }
      ],
      monthes:[
                    {value:'',label:'Month'},
                    {value:'0',label:'0'},
                    {value:'1',label:'1'},
                    {value:'2',label:'2'},
                    {value:'3',label:'3'},
                    {value:'4',label:'4'},
                    {value:'5',label:'5'},
                    {value:'6',label:'6'},
                    {value:'7',label:'7'},
                    {value:'8',label:'8'},
                    {value:'9',label:'9'},
                    {value:'10',label:'10'},
                    {value:'11',label:'11'},
                    {value:'12',label:'12'},
                ],
                years:[
                    {value:'',label:'Year'},
                    {value:'0',label:'0'},
                    {value:'1',label:'1'},
                    {value:'2',label:'2'},
                    {value:'3',label:'3'},
                    {value:'4',label:'4'},
                    {value:'5',label:'5'},
                    {value:'6',label:'6'},
                    {value:'7',label:'7'},
                    {value:'8',label:'8'},
                    {value:'9',label:'9'},
                    {value:'10',label:'10'},
                    {value:'11',label:'11'},
                    {value:'12',label:'12'},
                ]
    };
  },
  validations: {
    amccompany: {
      required
    },
    amcaddress: {
      required
    },
    amcphone: {
      required
    },
    amccontactperson: {
      required
    },
    payingcustomer: {
      required
    },
    payingcustomeraddress: {
      required
    },
    payingcustomerphone: {
      required
    },
    payingcustomercontactperson: {
      required
    },
    allWorkOrder: {
      required,
      minLength: minLength(1),
      $each: {
        title: { required },
        quantity: { required },
        contractduration: {
          years: {
            required,
            validator: function(e,value) {
              return !(value.years == 0 && value.months == 0)
            }
          },
          months: {
            required,
            
          }
        },
        //solution: { required },
        devicetemplate: { required },
        structure: { required },
        governmentauthority: { required },
        fmccompany: { required },
        amccompany: { required },
        operator:{required:false}
        //devicemanufacturer: { required }
      }
    }
  },
  computed: {
    solutions() {
      return this.$store.state.solutions.map(solution=>({value:solution.id,label:solution.name}))
    },
    amcaddress() {
      if (this.amccompany) {
        return this.amccompany.selectedObject.address;
      }
      return null;
    },
    amcphone() {
      if (this.amccompany) {
        let user = this.amccompany.selectedObject.users.find(
          usr => usr.type == users_types.Supervisor
        );
        return user.phone;
      }
      return null;
    },
    amccontactperson() {
      if (this.amccompany) {
        let user = this.amccompany.selectedObject.users.find(
          usr => usr.type == users_types.Supervisor
        );
        return user.email;
      }
      return null;
    },
    payingcustomeraddress() {
      if (this.payingcustomer) {
        return this.payingcustomer.selectedObject.address;
      }
      return null;
    },
    payingcustomerphone() {
      if (this.payingcustomer) {
        let user = this.payingcustomer.selectedObject.users.find(
          usr => usr.type == users_types.Supervisor
        );
        return user.phone;
      }
      return null;
    },
    payingcustomercontactperson() {
      if (this.payingcustomer) {
        let user = this.payingcustomer.selectedObject.users.find(
          usr => usr.type == users_types.Supervisor
        );
        return user.email;
      }
      return null;
    }
  },

    // mounted
    mounted(){



      

    },



  methods: {

    getSolutionName(workorder){
      console.log('workorder',workorder);
      if(workorder.devicetemplate.$model){
          let solution_id = workorder.devicetemplate.$model.selectedObject.solution_id;
          let solution = this.solutions.find(sol=>sol.value === solution_id);
          if(solution){
            return solution.label;
          }

      }
      return '';
    },

    removeworkorder(index) {
      console.log(index);
      this.allWorkOrder.splice(index, 1);
    },
    addWorkOrder(e) {
      e.preventDefault();
      let info = {
        title: "",
        quantity: "",
        contractduration: {
          years: '',
          months: ''
        },
        //solution: "",
        devicetemplate: null,
        structure: null,
        governmentauthority: null,
        fmccompany: null,
        amccompany: null,
        operator:null
        //devicemanufacturer: ""
      };
      this.allWorkOrder.push(info);
    },
    
    
    FmcEndpoint(input) {
      return `${this.$api.defaults.baseURL}fmc/search/${input}`;
    },
    AmcEndpoint(input) {
      return `${this.$api.defaults.baseURL}amc/search/${input}`;
    },
    payingCustomerEndpoint(input) {
      return `${this.$api.defaults.baseURL}customer/search/${input}`;
    },
    AuthorityEndpoint(input) {
      return `${this.$api.defaults.baseURL}authority/search/${input}`;
    },

    OperatorEndpoint(input) {
      return `${this.$api.defaults.baseURL}operator/search/${input}`;
    },
    TemplateEndpoint(input) {
      return `${this.$api.defaults.baseURL}template/search/${input}`;
    },
    StructureEndpoint(input) {
      return `${this.$api.defaults.baseURL}structures/search/${input}`;
    },
    // chooseStrucType() {
    //   this.$modal.show("hello-world");
    // },
    submit(e) {
      e.preventDefault();
      this.$v.$touch();
      console.log(this.$v);
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit("setSpinner", true);
        let devices = this.allWorkOrder.map(order => {
          //    let start = moment(order.contractduration.start).format("MM/DD/YYYY");
          //    let end = moment(order.contractduration.end).format("MM/DD/YYYY");
          return {
            name: order.title,
            structure_id: order.structure.selectedObject.id,
            contract_duration: `${order.contractduration.years}|${order.contractduration.months}`,
            //solution_id: order.solution,
            template_id: order.devicetemplate.selectedObject.id,
            authority_id: order.governmentauthority.selectedObject.id,
            fmc_id: order.fmccompany.selectedObject.id,
            amc_id: order.amccompany.selectedObject.id,
            operator_id: order.operator ? order.operator.selectedObject.id : null,
            quantity: order.quantity
          };
        });
        let total_quantity = devices.reduce(
          (acc, curr) => parseInt(curr.quantity) + acc,
          0
        );
        let data = {
          //po_id: this.po,
          amc_id: this.amccompany.selectedObject.id,
          customer_id: this.payingcustomer.selectedObject.id,
          total_quantity,
          devices
        };
        console.log("add work order data:", data);
        this.$api
          .post(`orders/create`, data)
          .then(res => {
            this.$router.push({ path: "/order/list" });
          })
          .catch(err => {});
      }
    }
  }
};
</script>
<style>
</style>
