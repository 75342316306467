<template>
  <card class="card px-2">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <inline-svg width="60" height="60"  :src="require('@/assets/Icons/globalmobility/scooter.svg')" />
        <h3 class="title ml-2 mb-0 font-weight-bold">Scooter {{scooterID}}</h3>
      </div>
      <div>
        <base-button
          type="primary"
          icon
          class="mr-2">
        <inline-svg :src="require('@/assets/Icons/globalmobility/qr_code-24px.svg')" />
        </base-button>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  name: "scooter-header",
  data(){
    return{
      scooterID: ""
    }
  },
  created(){
    this.scooterID = this.$route.params.id
  }
}
</script>

<style scoped>
.card{
  border-radius: 3px !important;
}
</style>