<template>
  <div class="scooter-map">
    <div class="option-above-map d-flex align-items-center">
    
      <div>
        <base-select
          :inline="true"
          :light="true"
          v-model="currentPeriodIndex"
          :options="periodsOptions"
          class="mb-0 mr-2 select"
        />
      </div>
    </div>
  
    <base-map
      :zoom="15"
      :center="center"
      v-if="!loading"
      style="height: calc(100% - 80px)"
      class="mt-3">
      <base-map-path
        :path="mapData.data"
      />
    </base-map>
   
  </div>
</template>

<script>
import BaseMap from "src/components/BaseMap/Map";
import MapPath from "src/components/BaseMap/MapPath.vue";
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import MapCircle from "src/components/BaseMap/MapCircle";

import { onePeriodInTwoDays } from "@/util/periodOptions";
import ScooterService from '../../../../services/globalMobility.service';

export default {
  name: 'map-section',
  components: {
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker,
    [MapPath.name]: MapPath,
    [MapCircle.name]: MapCircle,
  },
  props:{
    data: {
      type: Array
    },
    currentLocation:{
      type: String
    },
    filter:{
      type: Object
    }
  },
  data(){
    return{
      periodsOptions: onePeriodInTwoDays,
      currentPeriodIndex: 7,
      date: [new Date(Date.now() - 24 * 7 * 60 * 60 * 1000), new Date()],
      geofence: {},
      mapData: {},
      loading: false
    };
  },
  computed:{
    markers() {
      return this.mapData.data || [];
    },
    center() {
      return (
        this.markers[this.markers.length - 1] || {
          lng: 55.1228196,
          lat: 24.962552
        }
      );
    },
    firstPoint() {
      return this.markers[0] || { lng: 54.1228196, lat: 24.962552 };
    },
    lastPoint() {
      return (
        this.markers[this.markers.length - 1] || {
          lng: 55.1228196,
          lat: 24.962552
        }
      );
    },
  },
  watch: {
    currentPeriodIndex(val) {
      this.date[0] = this.periodsOptions[val].from().toDate();
      this.date[1] = this.periodsOptions[val].to().toDate();
      this.updateData();
    }
  },
  async mounted() {
    await this.updateData();
  },
  methods:{
    async updateData() {
      this.date[0] = this.periodsOptions[this.currentPeriodIndex]
          .from()
          .toDate();
        this.date[1] = this.periodsOptions[this.currentPeriodIndex]
          .to()
          .toDate();
      let start = this.date[0].toISOString();
      let end = this.date[1].toISOString();
      const payload = {
        id: this.$route.params.id,
        start: start,
        end: end
      }
      this.loading = true;
      let data = await ScooterService.getTimeline(payload);
      this.loading = false;
      this.mapData = data.data;
    },
    async getGeofence() {
      let data = await ScooterService.getGeofence(this.$route.params.id);
      this.geofence = data.data.data[0];
    },
  },
}
</script>

<style lang="scss" scoped>
.scooter-map{
  position: relative;
  overflow: hidden;
  height: 100%
}
.option-above-map{
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 90
}
.above-map-left {
  position: absolute;
  top: 150px;
  left: 14px;
  z-index: 9;
  width: 300px;
  padding: 8px;
  background: #8d8d8de3;
  p,
  h4 {
    color: white !important;
  }
}
.select{
  width: 170px;
}
.map-popup{
  position: absolute;
  top: 100px;
  left: 200px;
  z-index: 9
}
.black-color{
  color: #294A64  !important
}
</style>