<template>
  <card class="card" v-loading="loading">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2" >
        <h4  slot="reference" class="ml-2 font-color-1 font-weight-bold">Scooter Info</h4>
        <el-popover
          placement="bottom-end"
          width="250"
          height="300"
          trigger="hover" >
          <!-- <inline-svg
            width="40"
            height="40"
            :src="require('@/assets/Icons/forklift/noun_Inventory Control.svg')" /> -->
          <div slot="reference">
            <base-button
              type="primary"
              icon
              class="mr-2">
            <inline-svg :src="require('@/assets/Icons/globalmobility/qr_code-24px.svg')" />
            </base-button>
          </div>
        </el-popover>
      </div>
    </template>
      <div class="row">
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Model
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{data.name}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Device IMEI
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{data.imei}}
          </span>
        </div>
      </div>
     
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Serial Number
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{data.serial_number ? data.serial_number : "serial number"}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Year
          </span>
          <span class="value font-color-1 font-weight-bold">
            {{data.model}}
          </span>
        </div>
      </div>
    <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Date of purchase
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{dateFormat(data.purchased_at)}}
          </span>
        </div>
      </div>
     <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Warranty
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{dateFormat(data.insurance_expiry)}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Max speed
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{data.max_speed}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Battery Voltage
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{data.battery_voltage}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
             Battery current
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{data.battery_current}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
           Scooter Temperature
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{data.scooter_temperature}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            External Voltage
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{data.external_voltage}}
          </span>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-between my-3">
          <span class="key font-color-1">
            Battery Temperature
          </span>
          <span class="value font-color-1 font-weight-bold">
           {{data.battery_temperature}}
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="row border-bottom">
      <div class="col-6">

        <span class="title font-weight-bold font-color-4">Model:</span>
        <p class="value">{{data.name}}</p>

        <span class="title font-weight-bold font-color-4">Device IMEI:</span>
        <p class="value">{{data.imei}}</p>

        <span class="title font-weight-bold font-color-4">Device IMEI:</span>
        <p class="value">{{data.imei}}</p>
        <span class="title font-weight-bold font-color-4">Serial Number:</span>
        <p class="value">{{data.serial_number ? data.serial_number : "serial number"}}</p>
      </div>
      <div class="col-6 pr-4">
        <span class="title font-weight-bold font-color-4">Year:</span>
        <p class="value">{{data.model}}</p>
        <span class="title font-weight-bold font-color-4">Date of purchase:</span>
        <p class="value">{{dateFormat(data.purchased_at)}}</p>
        <span class="title font-weight-bold font-color-4">Warranty:</span>
        <p class="value">{{dateFormat(data.insurance_expiry)}}</p>
        <span class="title font-weight-bold font-color-4">Max speed:</span>
        <p class="value">{{data.max_speed}}</p>
      </div>
    </div> -->
    <!-- <div class="row mt-4">
      <div class="col-6">
        <span class="title font-weight-bold font-color-4">Battery Voltage:</span>
        <p class="value">{{data.battery_voltage}}</p>
        <span class="title font-weight-bold font-color-4">Battery current:</span>
        <p class="value">{{data.battery_current}}</p>
        <span class="title font-weight-bold font-color-4">Scooter Temperature:</span>
        <p class="value">{{data.scooter_temperature}}</p>
      </div>
      <div class="col-6 pr-4">
        <span class="title font-weight-bold font-color-4">External Voltage:</span>
        <p class="value">{{data.external_voltage}}</p>
        <span class="title font-weight-bold font-color-4">Battery Temperature:</span>
        <p class="value">{{data.battery_temperature}}</p>
      </div>
    </div> -->
  </card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dateFormat } from "@/util/helper"

export default {
  name: "info",
  data() {
    return {
      dropdownVisible: false,
      scooterID: ""
    };
  },
  computed:{
    ...mapGetters("GlobalMobility/ScooterStore/ScooterInfo",[
      'data',
      'loading'
    ])
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore/ScooterInfo', [
      'fetchInfoData'
    ]),
    dateFormat
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchInfoData(this.scooterID)
  }
};
</script>

<style scoped>
.card {
  border-radius: 3px !important;
}
.title {
  display: inline-block;
  font-size: 1rem;
  margin-bottom: 10px;
  padding-left: 10px;
}
.value {
  display: inline-block;
  text-align: right;
}
.popup {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>