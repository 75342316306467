<template>
  <div>
    <edit-permission :isOpen="editPopupVisible" @close="close" />
  </div>
</template>

<script>
import EditPermission from '../modals/EditPermission'
export default {
  name: "",
  props:{
    editPopupVisible: {
      type: Boolean
    }
  },
  components:{
    EditPermission,
  },
  computed:{
    show:{
        get(){
          return this.isOpen;
        },
        set(val){
          this.$emit('close')
        }
    }
  },
  methods:{
    close(){
      this.$emit('close')
    },
  }
}
</script>