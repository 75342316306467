<template>
  <div class="row">
     <div class="col-md-5">
        <base-select
         v-model="value"
         :label='$t("search.targetType")+" :"'
         :options="targets"
         size="sm"
         @input="input"
        :inline="true"
         />
    </div>
    <div class="col-md-7">
      <auto-copmlete
        class="border"
        :placeholder="$t('search.searchFor') + $t('search.targetType')"
        :source="endPoint"
        v-model="selectedTargetValue"
        :resultsDisplay="selectedTarget.keyToDisplay"
        resultsProperty="data"
        ref="search"
      />
    </div>

  </div>
</template>

<script>
import AutoCopmlete from "@/components/BaseAutoComplete.vue";
import axios from "../../axiosConfig";
export default {
  data:function() {
    return {

      targets: [
        {
          label:"structure",
          value:"structure",
          type: "structure",
          url: "structures/search/",
          keyToDisplay: "structure_name",
        },
        {
          label:"company",
          value:"company",
          type: "company",
          url: "company/search/",
          keyToDisplay: "name",
        },
        {
          label:"device",
          value:"device",
          type: "device",
          url: "devices/search/",
          keyToDisplay: "name",
        },
        {
          label:"user",
          value:"user",
          type: "user",
          url: "user/search/",
          keyToDisplay: "email",
        },
      ],
      value:undefined,
      selectedTarget: undefined,
      selectedTargetValue: null,
    };
  },
  computed: {
    endPoint(query) {
      return axios.defaults.baseURL + this.selectedTarget.url;
    },
  },
  created() {
    this.selectedTarget = this.targets[1];
    this.value=this.targets[1].value;
  },
  methods: {
    reset() {
      this.$refs["search"].clear();
    },
    input(val){
      this.selectedTarget=this.targets.filter(e=>e.value==val)[0];
    }
  },
  watch: {
    selectedTargetValue: {
      handler(val) {
        if (val) {
          this.$emit("target", {
            target_key: this.selectedTarget.type + "_id",
            target_id: val.selectedObject.id,
          });
        } else {
          this.$emit("target", null);
        }
      },
    },
    selectedTarget: {
      handler() {
        this.$emit("target", null);
        this.reset();
      },
    },
  },
  components: {
    AutoCopmlete,
  },
};
</script>

<style scoped lang="scss">
.border {
  border: 1px solid #2dce89;
}
label {
  text-transform: capitalize;
  top: -30px;
  left: 0;
}

[dir="rtl"] label {
  right: 0;
  left: initial;
}
</style>
