<template>
  <card class="px-2 py-1 full-height-card" v-loading="mileLoading">
    <h4 class="mb-0 font-color-4 font-weight-bold border-bottom pb-2">Mileage</h4>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Trip Mileage</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{Math.round(data.single_mileage)}} km</span>
    </div>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Remaining Mileage</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{Math.round(data.actual_remaining_mileage)}} km</span>
    </div>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Predicted Mileage</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{Math.round(data.predicted_remaining_mileage)}} km</span>
    </div>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Total Mileage</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{Math.round(data.total_mileage)}} km</span>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "scooter-mileage",
  data(){
    return{
      scooterID: ""
    }
  },
  computed:{
    ...mapGetters('GlobalMobility/ScooterStore', [
      'mileData',
      'mileLoading'
    ]),
    data(){
      return{
        single_mileage: this.mileData.single_mileage / 1000,
        actual_remaining_mileage: this.mileData.actual_remaining_mileage / 1000,
        predicted_remaining_mileage: this.mileData.predicted_remaining_mileage / 1000,
        total_mileage: this.mileData.total_mileage / 1000,
      } 
    }
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore', [
      'fetchMileageData'
    ]),
    fetchData(){
      const payload = {
        id: this.scooterID
      }
      this.fetchMileageData(payload)
    }
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchData()
  }
}
</script>

<style scoped>
.white-content .bg-grey{
  background: #f3f3f6
}
.bg-grey{
  background: #47495d
}
</style>