<template>
  <card class="card custom-history" v-loading="loading">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <h4 class="ml-2 font-color-1 font-weight-bold">Customer History</h4>
      </div>
    </template>
    <div>
      <base-select
        :light="false"
        v-model="value"
        :options="options"
        placeholder="Filter"
        :editStyle="true"
        class="mb-0 mr-2"
        popperClass="select"
      /> 
    </div>
    <div class="d-flex align-items-center justify-content-between my-3 pr-2">
      <!-- <Search :propsToSearch="propsToSearch" @input="(value)=>{filter = value}" /> -->
      <base-input class="mb-0">
        <el-input
          type="search"
          class="mb-0 search-input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="Search"
          v-on:keydown.enter="applyFilter"
          aria-controls="datatables"
        ></el-input>
      </base-input>
      <base-button
        type="primary"
        icon
        class="mr-2">
      <inline-svg :src="require('@/assets/Icons/globalmobility/history-24px.svg')" />
      </base-button>
    </div>
    <el-table :data="data" class="striped-table">
      <el-table-column align="center" prop="name" label="Name" min-width="120px" />
      <el-table-column align="center" prop="id" label="ID" min-width="120px" />
      <el-table-column align="center" label="Start Time" min-width="120px">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.craeted_at)}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="end_time" label="End Time" min-width="120px">
        <template slot-scope="scope">
          {{ scope.row.ended_at ? dateFormat(scope.row.ended_at) : '--'}}
        </template>
      </el-table-column>
      <el-table-column align="center"  label="Duration" min-width="120px">
        <template slot-scope="scope">
          {{fomatDuration(scope.row.duration)}} h
        </template>
      </el-table-column>
      <el-table-column align="center" prop="distance" label="Distance" min-width="120px">
        <template slot-scope="scope">
          {{ Math.round(scope.row.distance / 1000)}} km
        </template>
      </el-table-column>
      <el-table-column align="center" prop="amount_charged" label="Charged" min-width="120px" />
      <el-table-column align="center" prop="alarms" label="Alarms Count" min-width="120px" />
    </el-table>
    <TablePagination
      slot="footer"
      ref="paginator"
      @change="updatePagination"
    ></TablePagination>
  </card>
</template>

<script>
// add later to pagination
// :total="inventoryTotal"
import TablePagination from "@/components/TablePagination.vue";
import { mapActions, mapGetters } from 'vuex';
import { dateFormat } from "@/util/helper"
import moment from 'moment'
// import Search from "@/components/Search.vue";

export default {
  name: "customer-history",
  components:{
    TablePagination,
    // Search
  },
  data(){
    return{
      scooterID: "",
      propsToSearch: [
        { value: "name", label: 'name' },
      ],     
      value: "",
      options: [], 
      pagination: {
        offset: 0,
        limit: 5,
      },
    }
  },
  computed:{
    ...mapGetters('GlobalMobility/ScooterStore/ScooterCustomerHistory', [
      'data',
      'loading'
    ]),
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore/ScooterCustomerHistory', [
      'fetchCustomerData'
    ]),
    fetchData(){
      const payload = {
        id: this.scooterID,
        offset: this.pagination.offset,
        limit: this.pagination.limit
      }
      this.fetchCustomerData(payload)
    },
    updatePagination(data) {
      this.pagination.limit = data.limit;
      this.pagination.offset = data.offset;
      this.fetchData()
    },
    dateFormat,
    fomatDuration(value) {
      return moment.duration(value).hours()
    }
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchData()
  }
}
</script>

<style >
.card{
  border-radius: 3px !important;
}
.search-input {
  width: 300px 
}
.custom-history .select-primary.el-select .el-input input {
  background: #e3f1ee !important;
  border-radius: 3px !important;
}

</style>