<template>
    <card class="remove-p full-height-card">
        <div class="d-flex h-100">
          <div class="col-6" style="border-right: 1px solid #e8e8e8">
            <div class="p-3 pb-0">
              <div class="py-2">
                <h4 class="primary-header font-weight-bold m-0">
                  {{ $t("structureWords.structureName") }}
                </h4>
                <span class="font-color-3">{{
                  data.structureName || "--"
                }}</span>
              </div>
            <div class="py-2">
              <h4 class="primary-header font-weight-bold m-0">City</h4>
              <span class="font-color-3">{{
                data.cityName || "--"
              }}</span>
            </div>
            <div class="py-2">
              <h4 class="primary-header font-weight-bold m-0">Community</h4>
              <span class="font-color-3">{{
                data.communityNameEn || "--"
              }}</span>
            </div>
            <div class="py-2">
              <h4 class="primary-header font-weight-bold m-0">longitude</h4>
              <span class="font-color-3">{{ data.lng || "--" }}</span>
            </div>
            </div>
          </div>
          <div class="col-6">
            <div class="p-3 pb-0">
              <div class="py-2">
                <h4 class="primary-header font-weight-bold m-0">Floors</h4>
                <span class="font-color-3">{{
                  data.floors || "--"
                }}</span>
                </div>
              <div class="py-2">
              <h4 class="primary-header font-weight-bold m-0">Country</h4>
              <span class="font-color-3">{{
                data.countryName || "--"
              }}</span>
              </div>
              <div class="py-2">
              <h4 class="primary-header font-weight-bold m-0">Customer</h4>
              <span class="font-color-3">{{
                data.customerName || "--"
              }}</span>
              </div>
              <div class="py-2">
              <h4 class="primary-header font-weight-bold m-0">latitude</h4>
              <span class="font-color-3">{{ data.lat || "--" }}</span>
              </div>
            </div>
          </div>
        </div>
    </card>
</template>

<script>
export default {
    props: {
        data: {}
    }
}
</script>

<style lang="scss" scoped>
.primary-header {
  color: $primary !important;
}
</style>