<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="submit">
        <card class="card-login card-white">
          <!-- <template slot="header">
            <img src="img/card-primary.png" alt="" />
            <h1 class="card-title">Set Password</h1>
          </template>-->
          <template slot="header">
            <div align="center">
              <!-- <img :src="logo" class="img-responsive" style="position: relative;width: 80px;" /> -->
              <h1 class="card-title">Set Password</h1>
            </div>
            <!-- <h1 class="card-title">Set Password</h1> -->
          </template>
          <div>
            <base-input
              name="password"
              type="password"
              v-model="$v.password.$model"
              placeholder="Password"
              addon-left-icon="tim-icons icon-email-85"
            >
              <template v-if="$v.password.$error" v-slot:error>
                <label class="error" v-if="!$v.password.required">Password is required</label>
                <label
                  class="error"
                  v-if="!$v.password.checkPassword"
                >password must be 8 letter with at least a symbol, upper and lower case</label>
              </template>
            </base-input>

            <base-input
              name="confirm password"
              type="password"
              v-model="$v.confirm_password.$model"
              placeholder="Confirm Password"
              addon-left-icon="tim-icons icon-email-85"
            >
              <template v-if="$v.confirm_password.$error" v-slot:error>
                <label class="error" v-if="!$v.confirm_password.required">Password is required</label>
                <label class="error" v-if="!$v.confirm_password.sameAsPassword">Not same password</label>
              </template>
            </base-input>

            <base-input
              name="name"
              v-model="$v.name.$model"
              placeholder="Name"
              addon-left-icon="tim-icons icon-email-85"
              v-if="!resetPasswordMode"
            >
              <template v-if="$v.name.$error" v-slot:error>
                <label class="error" v-if="!$v.name.required">Name is required</label>
              </template>
            </base-input>
            <base-input
              name="lastname"
              v-model="$v.lastname.$model"
              placeholder="Last name"
              addon-left-icon="tim-icons icon-email-85"
              v-if="!resetPasswordMode"
            >
              <template v-if="$v.lastname.$error" v-slot:error>
                <label class="error" v-if="!$v.lastname.required">Last name is required</label>
              </template>
            </base-input>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="primary" class="mb-3" size="lg" block>Submit</base-button>
            <div class="pull-left">
              <h6>
                <router-link class="link footer-link" to="/login">back</router-link>
              </h6>
            </div>

            <!-- <div class="pull-right">
              <h6><a href="#pablo" class="link footer-link">Need Help?</a></h6>
            </div>-->
          </div>
        </card>
      </form>
    </div>
    <el-dialog :visible.sync="showModal" :append-to-body="true">
      <div class="row">
        <div class="col-12 mb-4">
          <h4
            class="mb-0"
          >Your account is protected with OTP, please scan this QR using your phone with any QR Scaner.</h4>
          <p>Like Google Authenticator.</p>
        </div>
      </div>
      <div class="col-12 text-center pb-2">
        <img @load="qrLoading=false" @error="qrLoading=null" :src="qr" alt />
      </div>
      <div slot="footer" class="d-flex justify-content-end">
        <base-button type="success" @click="showModal=false">Done</base-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showModal2"
      :close-on-press-escape="false"
      :append-to-body="true"
    >
      <div class="row" v-if="qrLoading!=null">
        <div class="col-12 mb-4">
          <p>you'll find on your authentication app a code for this platform that changes periodically. this code will be required whenever you try to login.</p>
          <p class="mt-5">Are you sure you scan it correctly?</p>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 mb-4">
          <p>the QR code fail to load please contact with support team to resend it </p>
        </div>
      </div>
      <div slot="footer" class="row justify-content-around" v-if="qrLoading!=null">
        <base-button type="success" @click="goToLogin">Yes, go To Login</base-button>
        <base-button type="danger" @click="showModal2=false">No, Show QR again</base-button>
      </div>
      <div slot="footer" v-else class="row">
        <base-button type="success" @click="goToLogin">go To Login</base-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}
import { Dialog } from "element-ui";
import {
  required,
  requiredIf,
  sameAs
  //minLength,
  //email
  //numeric
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      password: "",
      confirm_password: "",
      //notification_email: "",
      //contact_person: "",
      name: "",
      lastname: "",
      id: "",
      token: "",
      resetPasswordMode: this.$router.currentRoute.query.reset == "true",
      qr: null,
      showModal: false,
      showModal2: false,
      qrLoading:false
    };
  },
  watch: {
    showModal: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.showModal2 = true;
        }
      }
    },
    showModal2: {
      handler(newVal, old) {
        if (old) {
          this.showModal = true;
        }
      }
    },
    qrLoading:{
      handler(newVal,oldVal){
        if(newVal==null){
          this.$store.commit('setSpinner',false)
          this.showModal=false;
        }
        this.$store.commit('setSpinner',newVal)
      }
    }
  },
  components: {
    [Dialog.name]: Dialog
  },
  created() {},
  validations: {
    name: {
      required: requiredIf(function() {
        return !this.resetPasswordMode;
      })
    },
    lastname: {
      required: requiredIf(function() {
        return !this.resetPasswordMode;
      })
    },
    password: {
      required,
      checkPassword
    },
    confirm_password: {
      sameAsPassword: sameAs("password")
    }
  },
  mounted() {
    let path = window.location.pathname;

    //Break the path into segments
    let segments = path.split("/");
    console.log(segments);
    this.id = segments[2];
    this.token = segments[3];
  },
  computed: {
    logo() {
      return process.env.VUE_APP_LOGO;
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let data2 = {
          password: this.password,
          token: this.token,
          first_name: this.resetPasswordMode?undefined:this.name,
          last_name: this.resetPasswordMode?undefined:this.lastname
        };
        this.$store.commit('setSpinner',true)
        this.$api
          .post(`auth/${(this.resetPasswordMode?"fillpassword":"fillcredential")}/${this.id}`, data2)
          .then(({ data }) => {
            if (data.qr) {
            this.qr = data.qr;
              this.qrLoading=true;
              this.showModal = true;
            } else {
              this.$notify({
                title: "Success",
                message: "Credentials Saved",
                timeout: 3000,
                icon: "tim-icons icon-bell-55",
                type: "success"
              });
              this.$router.push({ name: "Login" });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    t(){
      console.log('trtrtr')
    }
  }
};
</script>
<style scoped>
h4 {
  word-break: break-word;
}
::v-deep .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
p {
  text-transform: capitalize;
  margin-top: 10px;
  letter-spacing: 0.8px;
  word-break: break-word;
}
</style>
