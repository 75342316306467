<template>
<card class="h-100 man-down">
    <div class="row">
      <div class="col-12">
          <h4 class="m-0">Man Down</h4>
          <span class="d-block "><u>Last time check: <span class="time">3 Minutes Ago</span></u></span>
      </div>
      
      <div class="position-absolute top-1 right-1">
          <inline-svg :src="require('@/assets/Icons/man-down.svg')" width="40" height="40" class="man-down-icon"  />
      </div>
      
      
    </div>

    <div class="mt-4">

        <!--  -->
        <div class="row align-items-center mb-5">
            <div class="col-2">
                <inline-svg :src="require('@/assets/Icons/worker.svg')" width="30" height="30" class="man-down-icon"  />
            </div>
            <div class="col-10"><span>Stewart</span></div>
        </div>
        <div class="row align-items-center mb-5">
            <div class="col-2">
                <inline-svg :src="require('@/assets/Icons/group.svg')" width="30" height="30" class="man-down-icon"  />
            </div>
            <div class="col-10"><span>4 Minuts ago</span></div>
        </div>
        <div class="row align-items-center mb-5">
            <div class="col-2">
                <inline-svg :src="require('@/assets/Icons/location-marker.svg')" width="30" height="30" class="man-down-icon"  />
            </div>
            <div class="col-10"><span>Downtown street</span></div>
        </div>

      


    </div>
  </card>
    
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .time{
        color:#229de3;
    }

</style>
<style>
    .man-down-icon path,.man-down-icon circle{
        fill: #f5455a;
    }
</style>
