<template>
  <card class="px-2 py-1 full-height-card">
    <h4 class="mb-0 font-color-4 font-weight-bold border-bottom pb-2">Time</h4>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Single Operation Time </h5>
      <span class="font-color-4 font-weight-bold mb-0">{{data.single_operation_time}} h</span>
    </div>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Total Operation Time</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{data.total_operation_time}} h</span>
    </div>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Single Riding Time</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{data.single_riding_time}} h</span>
    </div>
    <div class="bg-grey d-flex align-items-center justify-content-between my-3 px-3 py-2">
      <h5 class="font-color-4 font-weight-bold mb-0">Total Riding Time</h5>
      <span class="font-color-4 font-weight-bold mb-0">{{data.total_riding_time}} h</span>
    </div>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: "scooter-time",
  data(){
    return{
      scooterID: ""
    }
  },
  computed:{
    ...mapGetters('GlobalMobility/ScooterStore', [
      'timeData',
      'timeLoading'
    ]),
    data(){
      return{
        single_operation_time: moment.duration(this.timeData.single_operation_time).hours(),
        total_operation_time: moment.duration(this.timeData.total_operation_time).hours(),
        single_riding_time: moment.duration(this.timeData.single_riding_time).hours(),
        total_riding_time: moment.duration(this.timeData.total_riding_time).hours(),
      } 
    }
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore', [
      'fetchTimeData'
    ]),
    fetchData(){
      const payload = {
        id: this.scooterID
      }
      this.fetchTimeData(payload)
    },
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchData()
  }
}
</script>


<style scoped>
.white-content .bg-grey{
  background: #f3f3f6
}
.bg-grey{
  background: #47495d
}
</style>