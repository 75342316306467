<script>
  export default {
    name: "base-map-circle",
    props: {
      radius: { type: Number },
      center: {
        type: Object,
        default: () => ({ lat: 0, lng: 0 })
      },
    },
    data() {
      return {
        marker: null
      };
    },
    mounted() {
      this.$parent.getMap(map => {
        this.circle = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.1,
          map,
          center: this.center,
          radius: this.radius,
        });
      });
    },
    watch: {
      radius() {
        this.circle.setRadius(this.radius)
      },
      center() {
        this.circle.setCenter(this.center)
      }
    },
    beforeDestroy() {
      this.circle.setMap(null);
    },
    render() {
      return null;
    }
  };
</script>