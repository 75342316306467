<template>
  <card>
    <el-table :data="logs">
      <el-table-column
        sortable
        v-for="column in tableColumns"
        :key="column.label"
        :min-width="column.minWidth"
        :prop="column.prop"
        :label="$t(column.label)"
      ></el-table-column>
      <el-table-column :min-width="150" label="date" sortable prop="date">
        <div slot-scope="{ row }">
          <span>{{ gDateFormat(row.date) }}</span>
        </div>
      </el-table-column>

      <el-table-column :min-width="100" label="target" sortable prop="target">
      </el-table-column>
    </el-table>
    <TablePagination
      v-if="total"
      @change="updatePagination"
      ref="paginator"
      slot="footer"
      :total="total"
    ></TablePagination>
  </card>
</template>

<script>
import TablePagination from "@/components/TablePagination.vue";
export default {
  name: "LogsTable",
  props: ["logs", "total"],
  components: { TablePagination },
  methods: {
    updatePagination(pagination) {
      this.$emit("updatePagination", pagination);
    },
  },
  data: function () {
    return {
      tableColumns: Object.freeze([
        {
          prop: "user",
          label: "userName",
          minWidth: 150,
        },
        {
          prop: "source_ip",
          label: "sourceIP",
          minWidth: 125,
        },
        {
          prop: "level",
          label: "level",
          minWidth: 100,
        },
        {
          prop: "event",
          label: "event",
          minWidth: 300,
        },
        {
          prop: "action",
          label: "action",
          minWidth: 125,
        },
      ]),
    };
  },
};
</script>
