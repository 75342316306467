<template>
  <card class="h-100">
    <div class="d-flex pr-1 pl-1 justify-content-between align-items-start">
      <h4>Outdoor Tracker</h4>

      <base-dropdown
        :menu-on-right="!$rtl.isRTL"
        title-tag="div"
        class="nav-item"
        menu-classes="dropdown-navbar  alerts-body-tracker"
      >
        <template slot="title">
          <div class="photo">
            <h4>
              <span class="alarm position-relative ml-1">
                <!-- <img src="@/assets/Icons/bell.svg" class="imgicon" /> -->
                <inline-svg :src="require('@/assets/Icons/noun_Alert_1423459.svg')" width="40" height="40" />
                <span class="alarm-count position-absolute top-3 left-4 rounded-circle">
                  <span>34</span>
                </span>
              </span>
            </h4>
          </div>
        </template>
        <li>
          <div class="row mb-3 align-items-center ml-1">
            <div class="col-lg-2 col-md-2 col-sm-12 p-1 text-center">
              <inline-svg
                :src="require('@/assets/Icons/accident-2.svg')"
                width="35"
                height="35"
                class="rounded-circle p-1"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-10 p-1">
              <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
                <p>Accidents</p>
                <p>(5)</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-2 p-1">
              <a href="#">
                <i data-v-4af0aa13 class="tim-icons icon-simple-remove mb-2"></i>
              </a>
            </div>
          </div>
        </li>


        <li>
          <div class="row mb-3 align-items-center ml-1">
            <div class="col-lg-2 col-md-2 col-sm-12 p-1 text-center">
              <inline-svg
                :src="require('@/assets/Icons/accident-2.svg')"
                width="35"
                height="35"
                class="rounded-circle p-1"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-10 p-1">
              <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
                <p>Accidents</p>
                <p>(5)</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-2 p-1">
              <a href="#">
                <i data-v-4af0aa13 class="tim-icons icon-simple-remove mb-2"></i>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="row mb-3 align-items-center ml-1">
            <div class="col-lg-2 col-md-2 col-sm-12 p-1 text-center">
              <inline-svg
                :src="require('@/assets/Icons/accident-2.svg')"
                width="35"
                height="35"
                class="rounded-circle p-1"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-10 p-1">
              <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
                <p>Accidents</p>
                <p>(5)</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-2 p-1">
              <a href="#">
                <i data-v-4af0aa13 class="tim-icons icon-simple-remove mb-2"></i>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="row mb-3 align-items-center ml-1">
            <div class="col-lg-2 col-md-2 col-sm-12 p-1 text-center">
              <inline-svg
                :src="require('@/assets/Icons/accident-2.svg')"
                width="35"
                height="35"
                class="rounded-circle p-1"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-10 p-1">
              <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
                <p>Accidents</p>
                <p>(5)</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-2 p-1">
              <a href="#">
                <i data-v-4af0aa13 class="tim-icons icon-simple-remove mb-2"></i>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="row mb-3 align-items-center ml-1">
            <div class="col-lg-2 col-md-2 col-sm-12 p-1 text-center">
              <inline-svg
                :src="require('@/assets/Icons/accident-2.svg')"
                width="35"
                height="35"
                class="rounded-circle p-1"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-10 p-1">
              <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
                <p>Accidents</p>
                <p>(5)</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-2 p-1">
              <a href="#">
                <i data-v-4af0aa13 class="tim-icons icon-simple-remove mb-2"></i>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="row mb-3 align-items-center ml-1">
            <div class="col-lg-2 col-md-2 col-sm-12 p-1 text-center">
              <inline-svg
                :src="require('@/assets/Icons/accident-2.svg')"
                width="35"
                height="35"
                class="rounded-circle p-1"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-10 p-1">
              <div class="d-flex alert-item justify-content-between pr-3 pl-3 align-items-center">
                <p>Accidents</p>
                <p>(5)</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-2 p-1">
              <a href="#">
                <i data-v-4af0aa13 class="tim-icons icon-simple-remove mb-2"></i>
              </a>
            </div>
          </div>
        </li>
      </base-dropdown>
    </div>
    <div class="row justify-content-start map-options ml-2 mb-2">
      <button class="btn btn-simple btn-dribbble btn-1">
         <inline-svg :src="require('@/assets/Icons/live-location.svg')" width="20" height="20" class="mr-2" />
        <span>Live Location</span>
      </button>

      <button class="btn btn-simple btn-dribbble rounded-0">
        <inline-svg :src="require('@/assets/Icons/geo-location.svg')" width="20" height="20" class="mr-2" />
        <span >GeoFencing</span>
      </button>
      <button class="btn btn-simple btn-dribbble btn-3">
       <inline-svg :src="require('@/assets/Icons/live.svg')" width="20" height="20" class="mr-2" />
        <span>Live Path</span>
      </button>
    </div>
    <div class="row">
      <div class="col-12">
        <gmap-map :center="center" :zoom="17" style="width:100%;  height:300px" :options="options">
          <!-- <gmap-polyline
              v-if="path.length > 0"
              :path="path"
              :editable="false"
              @path_changed="updateEdited($event)"
              @rightclick="handleClickForDelete"
              ref="polyline"
          ></gmap-polyline>-->
        </gmap-map>
      </div>
    </div>
  </card>
</template>
<script>
export default {
  data() {
    return {
      options: {
        // mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      center: { lat: 25.2048, lng: 55.2708 },
    };
  },
};
</script>
<style scoped>
.map-options button {
  height: 30px;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  font-size: 15px;
}

@media (min-width: 528px) {
  .btn-1 {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .btn-3 {
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
@media (max-width: 528px) {
  .btn-1 {
    border-radius: 0;
  }
  .btn-3 {
    border-radius: 0;
  }
}

/* .map-options .btn-group-toggle{
    flex: 1;
} */
.alarm-count {
  width: 25px;
  height: 25px;
  background-color: #fff;
  text-align: center;
  padding-right: 1px;
  color: #000;
}

.alerts-body-tracker .alert-item {
  background-color: crimson;
  height: 30px;
  border-radius: 20px;
}
.alerts-body-tracker svg {
  background-color: crimson;
}

.alerts-body-tracker i {
  color: crimson;
  font-weight: bolder;
  font-size: 18px;
}
</style>
<style >
.alerts-body-tracker {
  min-width: 20rem !important;
  max-height: 250px;
  overflow-y:auto;
  overflow-x: hidden ;
}
</style>
