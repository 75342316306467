<template>
  <div v-if="data">
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_signal2.svg`)"
          width="20"
          height="20"
        />
        <span>Low GSM Signal</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data['low gsm'] }}</span>
      </div>
    </div>
    <div class="col-12 d-flex  font-color-1 justify-content-between my-4 pl-0">
      <div class="d-flex mr-2">
        <inline-svg
          :class="`mr-3 ${svgColor}`"
          :src="require(`@/assets/Icons/forklift/noun_signal2.svg`)"
          width="20"
          height="20"
        />
        <span>Low GNSS Signal</span>
      </div>
      <div class="d-flex align-items-center">
        <span class="point background-problem mr-1"></span>
        <span>{{ data['low gnss'] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    svgColor() {
      return this.$store.getters.themMode == "light" ? "" : "light-svg";
    }
  }
};
</script>

<style></style>
