<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <div class="customtableheader">
            <h4 slot="header" class="card-title">{{$t("Table.Release")}}</h4>
            <router-link to="/release/create">
              <el-tooltip content="Create Release" effect="light" :open-delay="300" placement="top">
                <base-button type="primary" round icon>
                  <i class="tim-icons icon-simple-add"></i>
                </base-button>
              </el-tooltip>
            </router-link>
          </div>

          <div>
            <el-table :data="release_list">
              <div slot="empty">Loading ...</div>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              ></el-table-column>

              <el-table-column :min-width="150" align="left" :label="$t('Created At')">
                <div slot-scope="props">{{gDateFormat(props.row)}}</div>
              </el-table-column>
              <el-table-column :min-width="35" align="right" :label="$t('Actions')">
                <div slot-scope="props">
                  <el-tooltip content="View" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="viewRelease(props.row)"
                      class="like btn-link"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-notes"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="handleEdit(props.row)"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                    <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import swal from "sweetalert2";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mounted() {
    this.$api
      .get(`releases`)
      .then((res) => {
        if (res.data.msg === "success") {
          this.release_list = res.data.data;
        }
      })
      .catch((err) => {});
  },
  data() {
    return {
      release_list: [],
      tableColumns: [
        {
          prop: "version",
          label: this.$t("Version"),
          minWidth: 50,
        },
        {
          prop: "last_modified",
          label: this.$t("Last Modified"),
          minWidth: 150,
        },
      ],
    };
  },
  methods: {
    viewRelease(release) {
      this.$router.push({ path: `view/${release.id}` });
    },
    handleEdit(release) {
      this.$router.push({ path: `update/${release.id}` });
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRelease(row, index);
        }
      });
    },
    deleteRelease(row, index) {
      this.$api
        .delete(`releases/${row.id}`)
        .then((_) => {
          // this.release_list.pop(row);
          this.release_list.splice(index, 1);
          swal({
            title: "Deleted!",
            text: `success`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        })
        .catch(console.error);
    },
  },
};
</script>
<style scoped>
::v-deep .el-dialog__body {
  padding-top: 0;
}
::v-deep .el-dialog {
  width: 70% !important;
  margin-top: 7vh !important;
}
</style>
