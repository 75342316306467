<template>
    <card footer-classes="text-right">
      <div class="Content">
        <!-- All Card Content -->
        <div class="row">
          <div class="col-md-6">
            <div class="col-12">
              <!-- Company Name field -->
              <base-input
                :placeholder="$t('Company Name')"
                v-model.trim="$v.name.$model"
              >
                <template v-if="$v.name.$error" v-slot:error>
                  <label class="error" v-if="!$v.name.required">{{
                    $t("requireds.name")
                  }}</label>
                </template>
              </base-input>
            </div>
            <div class="col-12">
              <!-- Company Type Select -->
              <base-select
                :placeholder="$t('Company Type')"
                v-model="$v.companyType.$model"
                :options="companyTypes"
              >
                <template v-if="$v.companyType.$error" v-slot:error>
                  <label class="error" v-if="!$v.companyType.required">{{
                    $t("requireds.Type")
                  }}</label>
                </template>
              </base-select>
            </div>
            <div class="col-12">
              <!-- Maximum Users Number -->
              <base-input
                placeholder="The maximum number of users"
                type="number"
                v-model.number="$v.maxUsers.$model"
              >
                <template v-if="$v.maxUsers.$error" v-slot:error>
                  <label class="error" v-if="!$v.maxUsers.range"
                    >The number of users must be greater or equal to 0</label
                  >
                </template>
              </base-input>
            </div>
            <div class="col-12">
              <!-- Country Select -->
            <countries-select :placeholder="$t('Country')" v-model="$v.country.$model">
                  <template v-if="$v.country.$error" v-slot:error>
                    <label class="error" v-if="!$v.country.required">{{$t("requireds.Country")}}</label>
                  </template>
                </countries-select >

            </div>
          </div>
          <div
            class="col-md-6 d-flex justify-content-center align-items-center"
          >
            <!-- Company Logo -->
            <image-upload
              type="avatar"
              :src="logoUrl"
              :select-text="$t('Add photo')"
              @change="onAvatarChange"
            />
          </div>
        </div>
      </div>
      <div class="col-12 row">
        <div class="col-md-6">
          <!-- City Select -->
           <cities-select :placeholder="$t('City')" v-model="$v.city.$model" :countryId="country.id">
                  <template v-if="$v.city.$error" v-slot:error>
                    <label class="error" v-if="!$v.city.required">{{$t("requireds.City")}}</label>
                  </template>
          </cities-select >
        </div>
        <div class="col-md-6 pl-3 pr-3">
          <!-- Location Field -->
            <gmap-autocomplete
              :placeholder="$t('enter location')"
              ref="gmap"
              @place_changed="setPlace"
            ></gmap-autocomplete>
        </div>
      </div>

      <div class="row">
        <!-- Add User -->
        <div class="col-12 form-group">
          <div class="add-plus">
            <a href="javascript:void(0)" class="plus-icon" @click="addUser">
              <i class="fa fa-plus"></i>
              {{ $t("Add Users") }}
            </a>
          </div>
        </div>
      </div>

      <div v-for="(user, index) in $v.users.$each.$iter" :key="index">
        <!-- Users Section -->

        <span class="aspointer cursorRed cursorRemove" @click="removeUser(index)" v-if="(index > 0) || (index==0 && users.length==1)">
          <!-- Remove User -->
          <i class="fa fa-minus-circle"></i>
          <span
            style="margin: 0 5px; color: #fff"
            >{{ $t("Remove") }}</span
          >
        </span>

        <div class="row mt-2">
          <div class="col-4">
            <!-- User Email Field -->
            <base-input :placeholder="$t('Email')" v-model="user.email.$model">
              <template v-if="user.email.$error" v-slot:error>
                <label class="error" v-if="!user.email.required">{{
                  $t("requireds.Email")
                }}</label>
                <label
                  class="error"
                  v-if="!user.email.email && user.email.required"
                  >{{ $t("validations.Not valid email") }}</label
                >
                <label
                  class="error"
                  v-if="
                    user.email.email && user.email.required && !user.email.exist
                  "
                  >{{ $t("validations.emailExist") }}</label
                >
              </template>
            </base-input>
          </div>

          <div class="col-4">
            <!-- User Phone Field -->
              <phone-input :placeholder="$t('Phone')" v-model="user.phone.$model" @phoneValid="validatePhone" :countryId="country.id">
                  <template v-if="user.phone.$error" v-slot:error>
                    <label class="error" v-if="!user.phone.required||user.phone.isvalidPhone">{{$t("requireds.Phone")}}</label>
                  </template>
                </phone-input >

          </div>

          <div class="col-4">
            <!-- User Type Select -->
            
            <base-select
              :placeholder="$t('User Type')"
              v-model="user.type.$model"
              :options="userTypes"
              :disabled="index == 0"
            >
              <template v-if="user.type.$error" v-slot:error>
                <label class="error" v-if="!user.type.required">{{
                  $t("requireds.Type")
                }}</label>
              </template>
            </base-select>
          </div>
        </div>
      </div>

      <!-- Add Contact Info -->
      <div class="row">        
        <div class="col-12 form-group">
          <div class="add-plus">
            <a href="javascript:void(0)" class="plus-icon" @click="addContact">
              <i class="fa fa-plus"></i>
              Add Contact Information
            </a>
          </div>
        </div>
      </div>

      <div v-for="(contact, index) in $v.contactInformations.$each.$iter" :key="index+1000">
         <!-- Remove Contact -->
        <span class="aspointer cursorRed cursorRemove" @click="removeContact(index)">
          <i class="fa fa-minus-circle"></i>
          <span
            style="margin: 0 5px; color: #fff"
            class="cursorRemove"
            >{{ $t("Remove") }}</span
          >
        </span>
        <div class="row mt-2">
          <!-- FirstName Field -->
          <div class="col-lg-3 col-md-6">
            <base-input placeholder="First Name" v-model="contact.firstName.$model">
              <template v-if="contact.firstName.$error" v-slot:error>
                <label class="error" v-if="!contact.firstName.required">
                  {{ $t("validations.firstNameRequired") }}
                </label>               
              </template>
            </base-input>
          </div>
          <!-- LastName Field -->
          <div class="col-lg-3 col-md-6">
            <base-input placeholder="Last Name" v-model="contact.lastName.$model">
              <template v-if="contact.lastName.$error" v-slot:error>
                <label class="error" v-if="!contact.lastName.required">
                 {{ $t("validations.lastNameRequired") }}
                </label>               
              </template>
            </base-input>
          </div>
          <!-- User Phone Field -->
          <div class="col-lg-3 col-md-6">
              <phone-input :placeholder="$t('Phone')" v-model="contact.phone.$model" @phoneValid="validatePhone" :countryId="country.id">
                <template v-if="contact.phone.$error" v-slot:error>
                  <label class="error" v-if="!contact.phone.exist">{{$t("validations.phoneExist")}}</label>
                  <label class="error" v-if="contact.phone.exist && (!contact.phone.required||contact.phone.isvalidPhone)">{{$t("requireds.Phone")}}</label>
                </template>
              </phone-input >
          </div>
          <!-- User Email Field -->
          <div class="col-lg-3 col-md-6">
            <base-input :placeholder="$t('Email')" v-model="contact.email.$model">
              <template v-if="contact.email.$error" v-slot:error>
                <label
                  class="error"
                  v-if="!contact.email.email"
                  >{{ $t("validations.Not valid email") }}</label
                >
              </template>
            </base-input>
          </div>
          
       </div>
        
      </div>

      <div class="row">
        <!-- Google Map -->
        <div class="col-12">
          <base-map :zoom="12" :center="center" style="height: 400px; margin-top: 30px">
            <base-map-marker v-if="marker" :position="marker" @click="center = marker"/>
          </base-map>
        </div>
      </div>

      <template slot="footer" class="text-right">
        <!-- Register Button -->
        <base-button
          native-type="submit"
          @click.prevent="submit"
          type="primary"
          role="button"
          >{{ $t("Register") }}</base-button
        >
      </template>
    </card>
</template>

<script>
import { ImageUpload } from "src/components/index";
import { required, minLength, email } from "vuelidate/lib/validators";
import { users_types } from "../../store/index.js";
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";

export default {
  components: {
    ImageUpload,
    [BaseMap.name]: BaseMap,
    [MapMarker.name]: MapMarker
  },
  data() {
    return {
      logoUrl: "",
      logo: null,
      name: "",
      country: "",
      city: "",
      location: null,
      center: { lat: parseFloat(25.11), lng: parseFloat(55.18) },
      marker: null,
      maxUsers: 2,
      companyType: "",
      companyTypes: [
        { value: "amc", label: "Annual Maintenance Company" },
        { value: "fmc", label: "Facility Management Company" },
        { value: "authority", label: "Regulatory Authority" },
        { value: "customer", label: "Paying Customer" },
        { value: "operator", label: "Operator" },
        { value: "police_station", label: "Police Station" },
      ],

      users: [
        {
          email: "",
          phone: "",
          type: "supervisor",
        },
        {
          email: "",
          phone: "",
          type: "",
        },
      ],
      userTypes: [],

      contactInformations:[
        {firstName:'',lastName:'',email:'',phone:''}
      ],

      phoneValid:false
    };
  },
  validations: {
    name: {
      required,
    },
    companyType: {
      required,
    },
    country: {
      required,
    },
    city: {
      required,
    },
    maxUsers: {
      range: (value) => value >= 0,
    },
    users: {
      $each: {
        email: {
          required,
          email,
          exist: function (value) {
            let Cnt = 0;
            for (let i = 0; i < this.users.length; i++) {
              Cnt += this.users[i].email == value;
            }
            return Cnt < 2;
          },
        },
        phone: {
          required,
          isvalidPhone:function(){
            return this.phoneValid;
          }
        },
        type: {
          required,
        },
      },
    },
    contactInformations: {
      $each: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          email,   
        },
        phone: {
          required,
          isvalidPhone:function(){
            return this.phoneValid;
          },
          exist:function(value) {
            let Cnt = 0;
            for (let i = 0; i < this.contactInformations.length; i++) {
              Cnt += this.contactInformations[i].phone == value;
            }
            return Cnt < 2;
          }
        }
      }
    },
  },
  mounted() {

    // Get User Types From store
    this.userTypes = Object.keys(users_types).map((key) => {
      return {
        label: key.replace(/_/g, ""),
        value: users_types[key],
      };
    });
  },
  watch: {
    maxUsers: function () {
      if (this.maxUsers < this.users.length) {
        this.maxUsers = this.users.length;
        this.$notify({
          type: "warning",
          title: "warning",
          message:
            "maximim number of user can't be less than current number of users",
        });
      }
    },
  },
  computed: {

  },
  methods: {
    validatePhone(evt){
        this.phoneValid=evt;
    },

    onAvatarChange(file) {
      this.logo = file;
    },
    setPlace(place) {
      if (this.country.name.trim() == "") {
        this.$refs.gmap.setTextValue("");
        this.$notify({
          type: "warning",
          title: "Error",
          message: "You Should Select a Country First",
        });
        return;
      }
      const addressComponents = place.address_components;
      if (!addressComponents) {
        this.$notify({
          type: "warning",
          title: "Error",
          message: "Please Choose A Valid Address",
        });
        return;
      }
      const validPlace =
        true ||
        addressComponents.some((value) => {
          return (
            value.long_name.toLowerCase().match(this.country.toLowerCase()) &&
            value.types.some((type) => {
              return type == "country";
            })
          );
        });
      if (validPlace) {
        this.location = place;
        if (this.location) {
          const marker = {
            lat: this.location.geometry.location.lat(),
            lng: this.location.geometry.location.lng(),
          };
          this.center = marker;
          this.marker = marker;
        }
      } else {
        // this.$refs.gmap.setTextValue("");
        this.address = null;
        this.$notify({
          type: "warning",
          title: "Error",
          message: "Can not choose address out of the selected Country",
        });
      }
    },
    addUser() {
      if (!this.maxUsers) {
        this.$notify({
          type: "warning",
          title: "Error",
          message: "Enter the maximum number of users field",
        });
        return;
      }
      if (this.users.length === this.maxUsers) {
        this.$notify({
          type: "warning",
          title: "Error",
          message:
            "users count should be less than or equal to maximum number of users",
        });
      } else {
        let x = { email: "", phone: "", type: "" };
        if(this.users.length == 0) {
          x.type = "supervisor";
        }
        this.users.push(x);
      }
    },
    removeUser(index) {
      if (this.users.length + this.contactInformations.length == 1) {
        this.$notify({
          type: "warning",
          title: "warning",
          message: "Company must have one user or one contact information",
        });
      } else {
        this.users.splice(index, 1);
      }
    },
    addContact() {
      this.contactInformations.push({firstName:'',lastName:'',email:'',phone:''});
    },
    removeContact(index) {
      if (this.users.length + this.contactInformations.length == 1) {
        this.$notify({
          type: "warning",
          title: "warning",
          message: "Company must have one user or one contact information",
        });
      } else {
        this.contactInformations.splice(index, 1);
      }
    },
    async submit() {
      this.$v.$touch();
      if (!this.marker || this.$refs.gmap.getTextValue() == "") {
        this.$notify({
          type: "warning",
          title: "Error",
          message: "Invalid Location",
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$notify({
          type: "danger",
          title: "Error",
          message: "There are Invalid Fields",
        });
        return;
      }
      let base64Logo = this.logo
        ? await this.$helper.getBase64(this.logo)
        : null;
      let extraUsers = JSON.parse(JSON.stringify(this.users));
      let allusers = extraUsers.map((user) => {
        user.phone = user.phone;
        return { ...user };
      });
      let contacts = this.contactInformations.map(contact => {
        return {
          first_name:contact.firstName,
          last_name:contact.lastName,
          phone:contact.phone,
          email:contact.email
        }
      });
      let data = {
        name: this.name,
        user_type: this.companyType,
        avatar: base64Logo,
        address: this.$refs.gmap.getTextValue(),
        city_id: this.city.id,
        country: this.country.id,
        allusers,
        contacts,
        max_users: this.maxUsers,
        ...this.center,
      };

      console.log("create ", data);
      this.$api
        .post(`${this.companyType}/create`, data)
        .then((res) => {
          this.$router.push({ path: "/Company/list" });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style>
.cursorRemove {
  cursor: pointer;
}
.cursorRed:hover {
  color: red;
}
</style>
