<template>
  <card class="card" v-loading="loading">
    <template slot="header">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pb-2"
      >
        <h4 class="ml-2 font-color-1 font-weight-bold">Speed</h4>
        <base-select v-model="periodIndex" :options="periodOptions" light />
      </div>
    </template>
    <div
      class="d-flex align-items-center justify-content-center chart-cover text-center"
    >
      <speedometer
        :value="speed.avg"
        :tickValue="speed.avg"
        :minValue="0"
        :maxValue="100"
        :ranges="[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        unit="km/h"
      />
    </div>
    <div class="d-flex align-items-center justify-content-around pb-3">
      <div class="span-text text-center py-4 background-light-poor">
        <p class="mt-2  h4 mb-2 font-weight-bold">Maximum Speed</p>
        <span class="mb-0 font-weight-bold mt-4">{{
          speed.max >= 0 ? `${speed.max}/km` : "40km/h"
        }}</span>
      </div>
      <div class="span-text text-center py-4 background-light-problem">
        <p class="mt-2 h4 mb-2 font-weight-bold">Speed Limit</p>
        <span class="mb-0 font-weight-bold mt-4">{{
          speed.speed_limit ? `${speed.speed_limit}/km` : "25km/h"
        }}</span>
      </div>
    </div>
  </card>
</template>

<script>
import Speedometer from "src/components/Charts/SpeedometerChart";
import { mapActions, mapGetters } from "vuex";
import { onePeriodInTwoDays } from "@/util/periodOptions";

export default {
  name: "speed",
  components: { Speedometer },
  data() {
    return {
      scooterID: "",
      date: [new Date(Date.now() - 24 * 60 * 60 * 1000), new Date()],
      periodOptions: onePeriodInTwoDays,
      periodIndex: 7
    };
  },
  computed: {
    ...mapGetters("GlobalMobility/ScooterStore/ScooterSpeed", [
      "speed",
      "loading"
    ])
  },
  watch: {
    periodIndex(val) {
      this.date[0] = this.periodOptions[val].from().toDate();
      this.date[1] = this.periodOptions[val].to().toDate();
      this.fetchData();
    }
  },
  methods: {
    ...mapActions("GlobalMobility/ScooterStore/ScooterSpeed", [
      "fetchSpeedData"
    ]),
    fetchData() {
      const payload = {
        id: this.scooterID,
        start: this.date[0].toISOString(),
        end: this.date[1].toISOString()
      };
      this.fetchSpeedData(payload);
    }
  },
  created() {
    this.scooterID = this.$route.params.id;
    this.date[0] = this.periodOptions[this.periodIndex].from().toDate();
    this.date[1] = this.periodOptions[this.periodIndex].to().toDate();
    this.fetchData();
  }
};
</script>

<style scoped>
.card {
  border-radius: 3px !important;
}
.span-text {
  padding: 12px 25px;
  border-radius: 8px;
}
.span-text {
  font-size: 40px;
}
.background-light-poor {
  background: #fbf2d9 !important;
}
.background-light-poor span {
  color: #ffd457;
}
.background-light-problem {
  background: #fbe0e6 !important;
}
.background-light-problem span {
  color: #e31e49;
}
.chart-cover,
.chartContainer {
  width: 100%;
  height: 250px;
  position: relative;
  margin: auto;
}
</style>
