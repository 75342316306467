<template>
  <card>
    <div class="row">
      <div class="col-md-11">
        <div class="co-1">
          <h4>
            {{$t('Version')}}:
            <strong style="color:gray">
              &nbsp;
              {{data.version}}
            </strong>
          </h4>
        </div>
      </div>
      <div class="col-md-1">
        <el-tooltip
          content="Create Release Change"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button type="primary" round icon @click="modalShowFun">
            <i class="tim-icons icon-simple-add"></i>
          </base-button>
        </el-tooltip>
        <el-dialog :visible="modalShow" :before-close="beforeClose">
          <div class="row">
            <div class="col-md-6">
              <base-select :placeholder="$t('Type')" v-model="$v.type.$model" :options="type_options">
                <template v-if="$v.type.$error" v-slot:error>
                  <label class="error" v-if="!$v.type.required">Type required</label>
                </template>
              </base-select>
              <base-input>
                <input placeholder="Task_No" class="form-control" v-model="$v.task_no.$model" />
              </base-input>
              <base-input>
                <textarea
                  class="form-control"
                  :placeholder="$t('Description')"
                  v-model="$v.description.$model"
                  rows="10"
                >
                  <template v-if="$v.description.$error" v-slot:error>
                    <label
                      class="error"
                      v-if="!$v.description.required"
                    >Description required</label>
                    </template>
                  </textarea>
              </base-input>
            </div>
            <div class="col-md-6" align="center">
              <image-upload :src="imageUrl" :select-text="$t('Add photo')" @change="onPhotoChange" />
            </div>
          </div>
          <div align="right">
            <base-button native-type="submit" @click.prevent="submit" type="primary">
              <span v-if="id">Update</span>
              <span v-else>Create</span>
            </base-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="co-1">
          <h4>
            {{$t('Last Modified')}}:
            <strong style="color:gray">
              &nbsp;
              {{data.last_modified}}
            </strong>
          </h4>
        </div>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-md-12">
        <el-table :data="data.release_changes">
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
          ></el-table-column>
          <el-table-column :min-width="50" align="left" :label="$t('Description')">
            <div slot-scope="props">
              <p style="white-space: pre">{{props.row.description}}</p>
            </div>
          </el-table-column>
          <el-table-column :min-width="50" align="left" label="Task_NO">
            <div slot-scope="props">
              <div v-if="props.row.task_no">{{props.row.task_no}}</div>
              <div v-else>-</div>
            </div>
          </el-table-column>
          <el-table-column :min-width="20" align="center" :label="$t('Actions')">
            <div slot-scope="props">
              <div class="search-bar input-group">
                <el-tooltip content="Edit" effect="light" :open-delay="300" placement="top">
                  <base-button
                    @click.native="handleEdit(props.row,props.$index)"
                    class="edit btn-link"
                    type="warning"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="Delete" effect="light" :open-delay="300" placement="top">
                  <base-button
                    @click.native="handleDelete(props.$index, props.row)"
                    class="remove btn-link"
                    type="danger"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Show Image"
                  effect="light"
                  v-if="props.row.image_url"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                    @click="viewFeature(props.row)"
                  >
                    <i class="tim-icons icon-camera-18"></i>
                  </base-button>
                </el-tooltip>
              </div>

              <el-dialog :visible="dialogVisible" v-if="dialogVisible" :before-close="beforeClose">
                <div align="center">
                  <img :src="dialogData.image_url" />
                </div>
              </el-dialog>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </card>
</template>
<script>
import { Dialog } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { ImageUpload } from "src/components/index";
import { Select, Option } from "element-ui";
import { required } from "vuelidate/lib/validators";
import swal from "sweetalert2";

export default {
  components: {
    [Option.name]: Option,
    ImageUpload,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      type_options: [
        { value: "feature", label: "feature" },
        { value: "fix", label: "fix" },
        { value: "refactoring", label: "refactoring" },
        { value: "security", label: "security" },
      ],
      modalShow: false,
      description: "",
      type: "",
      task_no: "",
      imageUrl: "",
      data: {},
      base64: null,
      image: "",
      id: "",
      index: "",
      dialogData: {},
      dialogVisible: false,
      tableColumns: [
        {
          prop: "type",
          label: this.$t("Type"),
          minWidth: 50,
        },
      ],
    };
  },

  validations: {
    task_no: {},
    description: {
      required,
    },
    type: {
      required,
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.$api
        .get(`releases/${this.id}`)
        .then((res) => {
          let data = res.data;
          if (data.msg === "success") {
            this.data = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    modalShowFun() {
      this.task_no = "";
      this.description = "";
      this.type = "";
      this.image = "";
      this.imageUrl = "";
      this.id = "";
      this.$v.$reset();
      this.modalShow = true;
    },
    async onPhotoChange(file) {
      let fileTemp = file;
      this.base64 = fileTemp ? await this.$helper.getBase64(fileTemp) : null;
      this.image = this.base64.toString();
    },
    beforeClose(done) {
      this.dialogVisible = false;
      this.modalShow = false;
      done();
    },
    viewFeature(data) {
      this.dialogVisible = true;
      this.dialogData = data;
    },
    async handleEdit(data, index) {
      this.index = index;
      this.modalShow = true;
      this.task_no = data.task_no;
      this.description = data.description;
      this.type = data.type;
      this.imageUrl = data.image_url;
      this.id = data.id;
      this.image = data.image_url;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$notify({
          type: "danger",
          title: "Invalid Data",
          message: "There are Invalid Fields",
        });
        return;
      } else {
        let data = {
          description: this.description,
          task_no: this.task_no,
          image_url: this.image,
          type: this.type,
        };
        console.log(data)
        if (this.id) {
          this.$api
            .put(
              `/releases/${this.$route.params.id}/releaseChanges/${this.id} `,
              data
            )
            .then((res) => {
              this.modalShow = false;
              this.description = "";
              this.task_no = "";
              this.image = "";
              this.type = "";
              this.data.release_changes[this.index].description =
                data.description;
              this.data.release_changes[this.index].task_no = data.task_no;
              this.data.release_changes[this.index].image = data.image_url;
              this.data.release_changes[this.index].type = data.type;
              this.data.release_changes[this.index].image_url = data.image_url;
            })
            .catch((err) => {});
        } else {
          this.$api
            .post(`/releases/${this.$route.params.id}/releaseChanges `, {
              releaseChanges: [data],
            })
            .then((res) => {
              data.id = res.data.releaseChangesIds[0][0];
              this.data.release_changes.push(data);
              this.modalShow = false;
              data.image_url = data.image;
              this.description = "";
              this.task_no = "";
              this.image = "";
              this.type = "";
              this.$v.$reset();
            })
            .catch((err) => {});
        }
      }
    },
    handleDelete(index, row) {
      swal({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteChange(row, index);
        }
      });
    },
    deleteChange(row, index) {
      this.$api
        .delete(`releases/${this.$route.params.id}/releaseChanges/${row.id}`)
        .then((_) => {
          this.data.release_changes.splice(index, 1);
          swal({
            title: "Deleted!",
            text: `success`,
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        })
        .catch(console.error);
    },
  },
};
</script>
<style scoped>
.white-content .icon-camera-18 {
  color: #1d253b;
}
.co-1 h5 {
  display: inline-block;
  margin: 0 10px 0 0;
}
.el-dialog__body {
  padding-left: 0%;
}
.form-control[disabled] {
  background-color: #32325d;
}
.SpPhone {
  border-color: #e14eca;
}

.cursorRemove {
  cursor: pointer;
}
.cursorRed:hover {
  color: red;
}

textarea.form-control {
  max-width: 100%;
  max-height: 80px;
  padding: 10px 10px 10px 10px;
  resize: none;
  border: 1px solid #2b3553;
  border: 1px solid lighten(black, 5%);
  border-radius: 10px;
  line-height: 2;
}
textarea.form-control:focus {
  border-color: #42b883;
}
</style>
