<template>
  <card class="card">
    <template slot="header">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2" >
        <div class="d-flex align-items-center">
          <h3 class="title ml-2 mb-0">Payment</h3>
        </div>
        <div>
          <div slot="right-footer" class="rounded">
            <base-button
              :round="false"
              size="sm"
              :type="'primary'"
              :simple="!type.dataType"
              class="py-1 btn-tab"
              v-for="(type, i) in types"
              :key="i"
              @click="changeDateType(type)"
              >{{ $t(`${type.title}`) }}</base-button
            >
          </div>
        </div>
        <div>
          <h4 class="title ml-2 mb-0"><b>Total: ${{payments.total}}</b></h4>
        </div>
      </div>
    </template>
    <div
      class="d-flex align-items-center justify-content-center mt-4 chart-cover"
    >
      <ZingchartWrapper
        :customConfiguration="{}"
        :series="chartSeries"
        :labels="chartLabels"
        height="350px"
        style="width: 100%"
        :requireZoom="false"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between mt-2 px-3">
      <div class="d-flex align-items-center justify-content-between">
        <span class="h2 span-text mb-0"><b>+$5</b></span>
        <p class="ml-3">
          <b> Exepted collection: <br /> $20 per day</b>
        </p>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <span class="h2 span-text mb-0"><b>+${{payments.total}}</b></span>
        <p class="ml-3">
          <b
            >Amount collected: <br />
            Today
          </b>
        </p>
      </div>
    </div>
  </card>
</template>

<script>
import ZingchartWrapper from "@/components/Charts/zingchart-wrapper";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "payment",
  components: { ZingchartWrapper },
  data() {
    return {
      scooterID: "",
      types: [
        {
          dataType: true,
          title: "Daily",
        },
        {
          dataType: false,
          title: "Weekly",
        },
        {
          dataType: false,
          title: "Monthly",
        },
      ],
      values: [3,13,23,13,45,32,23,1,35,4,2,3]
    };
  },
  computed: {
    chartSeries() {
      let series = [
        {
          values: this.payments.graph.values,
          color: "#419f8c",
        },
      ];

      return series;
    },
    chartLabels() {
      let labels = [{ labels: this.payments.graph.dates }];
      return labels;
    },
    ...mapGetters("GlobalMobility/ScooterStore/ScooterPayments",[
      'payments',
      'loading'
    ])
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchData()
  },
  methods: {
    ...mapActions('GlobalMobility/ScooterStore/ScooterPayments', [
      'fetchPaymentsData'
    ]),
    fetchData(){
      const payload = {
        id: this.scooterID
      }
      this.fetchPaymentsData(payload)
    },
    changeDateType(type) {
      this.types.forEach((tab) => {
        if (type.title == tab.title) {
          tab.dataType = true;
        } else {
          tab.dataType = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 3px !important;
}
.card .btn-tab {
  margin-right: -2px;
  border-radius: 1px;
  height: 35px;
}
.span-text {
  padding: 30px;
  border-radius: 8px;
  color: #71d6cd;
  background: #47495d
}
.white-content .span-text{
  background: #e3f1ee;
  color: #2d7972;
}
.chart-cover {
  overflow: hidden;
  width: 90%;
  height: 265px;
  position: relative;
  margin: auto;
}
</style>