<template>
  <card class="card full-height-card" v-loading="loading">
    <template slot="header" v-if="rentals">
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2" >
        <h4 class="ml-2 font-color-1 font-weight-bold">Rentals</h4>
      </div>
      <div class="d-flex align-items-center justify-content-between text-center mb-5 mt-5">
        <div class="span-text">
          <span class="h1 mb-0"><b>{{rentals.total ? rentals.total: "300"}}</b></span>
          <p class="mt-2"><b>Total number <br> of times</b></p>
        </div>
        <div class="span-text">
          <span class="h1 mb-0"><b>{{rentals.daily ? rentals.daily : "10"}}</b></span>
          <p class="mt-2"><b>Average number <br> of rentals of day</b></p>
        </div>
        <div class="span-text background-light-poor">
          <span class="h1 mb-0"><b>{{duration}} h</b></span>
          <p class="mt-2"><b>Average duration <br> per rental</b></p>
        </div>
        <div class="span-text background-light-poor">
          <span class="h1 mb-0"><b>{{distance}} km</b></span>
          <p class="mt-2"><b>Amount collected: <br> Today </b></p>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: "rentals",
  data(){
    return{
      scooterID: ""
    }
  },
  computed:{
    ...mapGetters("GlobalMobility/ScooterStore/ScooterRentals",[
      'rentals',
      'loading'
    ]),
    duration(){
      return moment.duration(this.rentals.average_duration).hours();
    },
    distance(){
      return Math.round(this.rentals.average_distance / 1000) ;
    },
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore/ScooterRentals', [
      'fetchRentalsData'
    ])
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchRentalsData(this.scooterID)
  }
}
</script>

<style scoped>
.card{
  border-radius: 3px !important;
}
.span-text{
  padding: 18px 10px;
  border-radius: 8px;
  background: #47495d;
  width: 22%;
}
.span-text span{
  color: #71d6cd;
  font-size: 25px
}
.white-content .span-text{
  background: #e3f1ee;
}
.white-content .span-text span{
  color: #2d7972;
}
.span-text p{
  font-size: 13px !important
}
.background-light-poor{
  background:  #58554d !important;
}
.white-content .background-light-poor{
  background: #fbf2d9 !important;
}
.background-light-poor span{
  color: #ffd457 !important
}
</style>