<template>
  <card class="scooter-map full-height-card">
    <header-data v-loading="headerLoading" :data="headerData"></header-data>
    <map-section :data="timelineData.data" :filter="filter" :currentLocation="timelineData.current_location" ></map-section>
    <template slot="footer">
      <div class="d-flex align-items-center justify-content-between px-3 mt-2">
        <div  class="d-flex align-items-center">
          <inline-svg width="35" height="35" :src="require('@/assets/Icons/globalmobility/location_on-24px.svg')"/>
          <h4 class=" mb-0 ml-2 font-weight-bold">Test Bidg</h4>
        </div>
        <div slot="right-footer" class="rounded">
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            class="py-2 m-0"
          >
            Route
          </base-button>
          <base-button
            :round="false"
            size="sm"
            :type="'primary'"
            :simple="filter.mapType === 0"
            class="py-2 m-0"
            @click="filter.mapType = 1 - filter.mapType"
          >
            Geofencing
          </base-button>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import HeaderData from './HeaderData.vue'
import MapSection from './MapSection.vue'
import { mapActions, mapGetters } from "vuex";

export default {
  name: "scooter-map",
  components: {
    HeaderData,
    MapSection
  },
  data(){
    return{
      filter: {
        mapType: 0
      },
      scooterID: ""
    }
  },
  computed:{
    ...mapGetters("GlobalMobility/ScooterStore/ScooterMap",[
      'headerData',
      'headerLoading',
      'timelineData',
      'timelineLoading'
    ])
  },
  methods:{
    ...mapActions('GlobalMobility/ScooterStore/ScooterMap', [
      'fetchBasicInfoData',
      'fetchTimeline'
    ]),
    changeDateType(type) {
      this.types.forEach((tab) => {
        if (type.title == tab.title) {
          tab.dataType = true;
        } else {
          tab.dataType = false;
        }
      });
    },
    fetchData(){
      const payload = {
        id: this.scooterID,
      }
      this.fetchTimeline(payload)
    },
  },
  created(){
    this.scooterID = this.$route.params.id
    this.fetchBasicInfoData(this.scooterID)
    this.fetchData()
  },
};
</script>

<style>
.scooter-map{
  border-radius: 3px !important;
  padding: 15px 0px !important
}
.scooter-map .card-body{
  padding: 0px !important
}
.card .btn-tab{
  margin-right: -2px;
  border-radius: 3px ;
  height: 35px
}
</style>