<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <form @submit.prevent="submit">
        <card class="card-login card-white">
          <template slot="header">
            <div align="center">
              <img :src="logo" class="img-responsive" style="position: relative;width: 80px;" />
            </div>
            <h1 class="card-title">Enter The 2FA Code</h1>
            <p>Please note that this code changes every two minutes</p>
            
          </template>

          <div>
            <base-input
              v-validate="'required'"
              name="code"
              v-model="model.code"
              placeholder="code"
              autocomplete="off"
              autofocus
            ></base-input>
          </div>

          <div slot="footer">
            <base-button
              native-type="submit"
              type="success"
              class="mb-3"
              size="lg"
              block
            >Get Started</base-button>
            <div class="pull-left">
              <h6>
                <router-link class="link footer-link" to="/login">back</router-link>
              </h6>
            </div>

            <!-- <div class="pull-right">
              <h6>
                <a href="#pablo" class="link footer-link">Need Help?</a>
              </h6>
            </div> -->
          </div>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        code: ""
      }
    };
  },
  computed: {
    logo() {
      return process.env.VUE_APP_LOGO;
    }
  },
  methods: {
    async submit() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        // TIP use this.model to send it to api and perform login call
        let holduserdata = this.$store.state.holduserdata;

        const data = {
          token: holduserdata.token,
          code: this.model.code,

        };
        this.$api
          .post("auth/verify", data)
          .then(res => {
            let data = res.data;

            if (data.msg == "success") {
              this.$store.commit("login", data.data);
              this.$store.commit("setToken", data.token);
              if (data.refresh_token != undefined) {
                localStorage.removeItem("refreshToken");
                this.$store.commit("setRefreshToken", data.refresh_token);
              }
              this.$router.push({ path: "/" });
              this.$store.commit("setholdUserData", null);
            }
          })
          .catch(error => {
            console.error(error);
            if(error.status==400){
              this.$router.push('/login')
            }
          });
      }
    }
  }
};
</script>
<style scoped>
p {
  color: var(--default);
  text-transform: capitalize;
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.card.card-white .card-title {
  font-weight: 600;
  color: rgb(46, 186, 116);
}

.card.card-white .form-control:focus,
.card.card-white .form-group .el-input__inner:focus,
.form-group .card.card-white .el-input__inner:focus,
.card.card-white .el-date-picker .el-input .el-input__inner:focus,
.el-date-picker .el-input .card.card-white .el-input__inner:focus {
  border-color: rgb(0, 242, 195) !important;
}
.footer-link,
.footer-link:hover,
.footer-link:focus,
.footer-link:active {
  color: rgb(46, 186, 116);
}
</style>
