<template>
  <div class="smart-tracker">
    <div class="row">
      <div class="col-12 p-1">
            <out-door-tracker />
      </div>
    </div>
    <div class="row">
        <div class="col-12 p-1">
            <smart-worker-tracker />
        </div>
        
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12  p-1">
        <Areas />
      </div>
      <div class="col-lg-4 col-md-6 p-1">
        <man-down />
      </div>
      <div class=" col-lg-4 col-md-6 p-1">
        <Attendance />
      </div>
    </div>


    <div class="row">
      <div class="col-12 p-1">
          <employess />
      </div>
    </div>
  </div>
</template>
<script>
import OutDoorTracker from './OutDoorTracker.vue';
import  SmartWorkerTracker from './SmartWorkerTracker.vue';
import ManDown from './manDown.vue';
import Areas from './Areas.vue';
import Attendance from './Attendance.vue';
import Employess from './Employees.vue'

export default {
    components:{
        OutDoorTracker,
        SmartWorkerTracker,
        ManDown,
        Areas,
        Attendance,
        Employess
    }
};
</script>
<style >
  .smart-tracker{
    color:#fff;
  }
</style>