<template>
    <div>
        <div class="map-section">
            <card :bodyClasses="'p-0'">
                <base-map v-if="data && data.lat" :zoom="12" :center="{lat:data.lat,lng:data.lng}" :style="`height: 350px`">
                  <base-map-marker v-if="data && data.lat" :position="{lat:data.lat,lng:data.lng}"/>
                </base-map>
            </card>
        </div>
        <CustomerTable v-if="data" :data="data" :columns="tableColumns"/>
        <div class="row">
            <div class="col-6">
                <VillaInformation v-if="data" :data="data" />
            </div>
            <div class="col-6">
                <card class="full-height-card">
                    <h5 slot="header">contacts list</h5>
                    <company-contacts-table :contacts="data.contacts"/>
                </card>
            </div>
        </div>
        <OperationalAlarmsTable
            :canFilter="false"
            :initFilters="{villaId:$route.params.id}"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseMap from 'src/components/BaseMap/Map'
import MapMarker from "src/components/BaseMap/MapMarker.vue";
import CompanyContactsTable from '@/pages/Profiles/components/CompanyContactsTable.vue';
import VillaInformation from '@/pages/VSAAS/components/VillaInformation';
import CustomerTable from '@/pages/VSAAS/pages/Customer-Info/components/CustomerTable';
import OperationalAlarmsTable from '../../core/OperationalAlarmsTable/index.vue';

export default {
    components: {
        CompanyContactsTable,
        [BaseMap.name]: BaseMap,
        [MapMarker.name]: MapMarker,
        VillaInformation,
        CustomerTable,
        OperationalAlarmsTable
    },
    computed: {
        ...mapGetters('VsaasStore/CustomerInfo',{
            loading: 'loading',
            villaId: 'villaId',
            data: 'data',
            tableColumns: 'tableColumns',
        })
    },
    methods: {
        ...mapActions({
            fetchData(dispatch,payload) {
                return dispatch('VsaasStore/CustomerInfo/fetchData');
            }
        })
    },
    created() {
        let id = this.$route.params.id;
        this.$store.commit('VsaasStore/CustomerInfo/setVillaId',id);
        this.fetchData();
    }
}
</script>

<style>

</style>